.section-fsdf-theme,
.section-agency {
  .col-middle-top {
    .panels-flexible-region-first {
        width: 137px;
        margin-right: 30px;
        margin-left: 50px;

        .panel-pane:first-of-type .field .field-label {
          margin-top: 0;
        }

        h2 {
          @extend %h4;
          color: color(heading);
        }

        .field-name-field-link-to-website {
          a {
            word-break: break-all;
            display: block;
          }
        }
      }
   }
}

.section-fsdf-theme {
  .field-name-field-theme-icon {
    float: left;
      background: white;
      border-radius: 50%;
      border: 2px solid;
      width: 60px;
      height: 60px;
      padding: 10px;

      img {
        display: block;
        height: auto;
      }
  }
}


.col-middle-top {
  .panels-flexible-region-first {
    width: 117px;
    margin-right: 30px;
    margin-left: 70px;

    .pane-entity-field {
      border-bottom: 1px solid #bfbfbf;
      padding-bottom: 30px;

      .field-label {
        @extend %h4;
        color: color(heading);
      }

      &:last-of-type {
        border-bottom: 0;
      }
    }
  }

  .panels-flexible-region-last {
    font-size: 24px;
    line-height: 36px;
    width: 450px;
  }
}

.section-agency {
  .col-middle-top {
    .panels-flexible-region-first {
      width: 200px;
      margin-left: 0;
    }

    .field-name-field-theme-icon {
      padding: 40px 0 0;
    }
  }
}

.field-label {
  @extend %h4;
}

// Styling National dataset list on theme page.
.pane-node-field-datasets-in-theme,
.pane-node-field-national-datasets {
  width: 70%;
  margin: auto;

  .field-item {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid rgba(191, 191, 191, .5);

    &:last-of-type {
      border-bottom: 0;
    }
  }
}

.view-fsdf-dataset {
  width: 70%;
  margin: auto;

  .item-list {
    ul {
      padding: 0;

      li {
        padding-bottom: 15px;
        margin-bottom: 15px;
        border-bottom: 1px solid rgba(191, 191, 191, .5);
        list-style: none;

        &:last-of-type {
          border-bottom: 0;
        }
      }
    }
  }
}

.field-name-field-work-package {
  .field-item {
    font-size: 16px;
    line-height: 18px;
    padding-bottom: 10px;
  }
}

.field-items {

  p {
    margin: 0 0 10px;
  }
}
