.pane-agency-contact-details {
  .field-label-inline {
    @include clearfix;
    margin: 5px 0;

    .field-label {
      margin-right: -100%;
      margin-top: 0;
      float: left;
      display: block;
      line-height: 18px;
    }

    .field-items {
      margin-left: 25px;
      float: left;
      display: block;
      font-size: 16px;
      line-height: 18px;
    }
  }
}
