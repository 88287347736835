// Collapsible fieldset
//
// Fieldsets with optional entry fields can be hidden with collapsed fieldsets.
//
// .is-collapsed - The collapsed fieldset.
//
// Markup: collapsible-fieldset.twig
//
// Style guide: forms.collapsible-fieldset

.collapsible-fieldset,
%collapsible-fieldset {
  position: relative;

  &__legend {

    a {
      display: block;
      background-image: url('../img/icon-arrow-down.png');
      background-position: 95% center;
      background-repeat: no-repeat;
      padding: 5px 15px 5px 5px;
      font-size: 16px;

      &:hover,
      &:focus {
        text-decoration: none;
        background-color: #eee;
      }

      @include rtl() {
        padding-left: 0;
        padding-right: 15px;
        background-position: right 4px top 50%;
      }
    }
    &.fsdf-dataset {
      a {
        background-position: 53% center;
      }
    }

  }

  &__summary {
    color: color(fieldset-summary);
    font-size: .9em;
    margin-left: .5em;
  }

  &.is-collapsed,
  &--is-collapsed {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
    height: 1em;
  }

  &.is-collapsed &__wrapper,
  &--is-collapsed__wrapper {
    display: none;
  }

  &.is-collapsed &__legend a,
  &--is-collapsed__legend a {
    background-image: url('../img/icon-arrow-right.png');

    @include rtl() {
      background-image: image-url('forms', 'collapsible-fieldset/collapsed-rtl.svg');
      background-position: right 4px top 50%;
    }
  }
}

//
// Drupal selectors.
//

.collapsible {
  @extend %collapsible-fieldset;

  & > legend .fieldset-legend {
    @extend %collapsible-fieldset__legend;

    .summary {
      @extend %collapsible-fieldset__summary;
    }
  }

  &.collapsed {
    @extend %collapsible-fieldset--is-collapsed;

    .fieldset-legend {
      @extend %collapsible-fieldset--is-collapsed__legend;
    }

    .fieldset-wrapper {
      @extend %collapsible-fieldset--is-collapsed__wrapper;
    }
  }
}


.view-input-datasets,
.view-agencies {
  .summary {
    @include visually-hidden;
  }

  .fieldset-legend {
    display: block;
  }

  .view-grouping-header {
    margin: 0;
  }

  .fieldset-wrapper {
    margin-top: 10px;
  }
}
