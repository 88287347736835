
// Admin logged in theming

#admin-menu {
  background: rgba(0, 0, 0, .3);
  box-shadow: none;
  -webkit-box-shadow: none;
  position: absolute !important;

  #admin-menu-wrapper {
    max-width: 1300px;
    margin: auto;    
  }

  @include respond-to('xxxl') {
    background: rgba(0, 0, 0, 1);
  }

  a {
    font-family: $source-sans;
    font-size: 14px;
  }

  .admin-menu-account {
    a {
      background: color(blue) !important;
    }
  }
}


ul.tabs {
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, .05);
  padding: 10px;
  border-radius: 4px;
  border-bottom: 1px solid white;
  box-shadow: inset 0 6px 15px -12px;
  background-image: none;
  display: flex;

  li {
    border-radius: 2px 0;
    background: none;

    &.tabs__tab is-active {

    }
  }
}


