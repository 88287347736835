// admin-menu and tranche page vertical tab menu styling

.page-tranche, .admin-menu {
  div.vertical-tabs {
      margin: auto;
      border: none;

      .view-header {
        margin-left: -100px;
      }

      ul.vertical-tabs-list {
        border: none;
        margin: -1px 30px -1px -100px;
        max-width: 240px;

        li {
          background: transparent;
          border: none;
          background: url("../img/title-bg.jpg") 0 bottom repeat-x;
          padding: 15px;

          &:last-of-type {
            background-image: none;
          }  

          a {
            @extend %h4;
            color: color(blue);
            margin: 0;

            span {
              font-size: 16px;
              text-transform: initial;
              line-height: 20px;
              font-weight: normal;              
            }
          }      
        }

        .selected {
          background-color: white;
          position: relative;
          border-radius: 0 0 4px 4px;
          border-bottom: 1px solid #dbdbdb;
          background-image: none;
          border-top: 1px solid #ebebeb;
          margin-top: -2px;

          &:after {
            content: " ";
            display: block;
            position: absolute;
            right: 0;
            top: 30%;
            background: url(../img/triangle-white-right.png) no-repeat;
            z-index: 100000;
            width: 20px;
            margin-right: -20px;
            height: 30px;

            a {
              transition: none;
            }
          }
        }

        ul {
          max-width: 100%;
          margin-left: 0;
        }
      }
  }  
}


  .vertical-tabs.clearfix {
    margin-bottom: 30px !important;
    background: url("../img/title-bg.jpg") 0 bottom repeat-x;
  }

  .form-actions {
    margin-top: 30px;
    float: right;

    #edit-preview {
      background: transparent;
      border: 1px solid #007ee5;
      color: #007ee5;
      margin-left: 10px;
    }
  }

.form-submit {
  border: 2px solid #dbdbdb;
  padding: 5px;
  min-width: 80px;
  color: #fff;
  background-color: #007ee5;
  background: -webkit-linear-gradient(#007ee5, #0071ce);
  background: linear-gradient(#007ee5, #0071ce);
  border-radius: 4px;
}

.view-content {
  ul {
    list-style: none;
  }
}
