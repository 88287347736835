// Headings
//
// All HTML headings, `<h1>` through `<h6>`, are available. `%h1` through `%h6`
// classes are also available, for when you want to match the font styling of a
// heading but still want your text to be displayed inline.
//
// Markup: headings-h.twig
//
// Style guide: base.headings

// Address variable `h1` font-size and margin within `section` and `article`
// contexts in Firefox 4+, Safari, and Chrome.
h1,
%h1 {
  // Set the font-size and line-height while keeping a proper vertical rhythm.
  @include type-layout(xxl, 2);

  // Set 1 unit of vertical rhythm on the top and bottom margins.
  @include margin-top(1, xxl);
  @include margin-bottom(1, xxl);
  @include typeface(compact);
  letter-spacing: 10px;
  text-align: center;
  line-height: 67px;
}

.front {
  h1 {
    max-width: 760px;
    margin: 0 auto;
  }
}

h2,
%h2 {
  @include type-layout(l, 1.5);
  @include margin-top(1, l);
  @include margin-bottom(1, l);
  @include typeface(compact);
  letter-spacing: 3px;
}

.page-home, .pane-1 {
  h2 {
    text-align: center;
    color: color(heading);
  }
}

.page-home {
  h2 {

    &:before {
        height: 55px;
        width: 1px;
        content: ' ';
        background-color: color(brown-caramel);
        display: block;
        margin: 0 auto 20px;
    }
  }
}

h3,
%h3 {
  @include type-layout(l, 1.5);
  @include margin-top(1, l);
  @include margin-bottom(1, l);
  //@include typeface(compact);
  line-height: 32px;
  margin-bottom: 10px;
  font-weight: normal;
}

h4,
%h4 {
  @include type-layout(xs, 1);
  @include margin-top(1, xs);
  @include margin-bottom(0, xs);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: color(brown-caramel);
}

h5,
%h5 {
  @include type-layout(s, 1);
  @include margin-top(1, s);
  @include margin-bottom(1, s);
}

h6,
%h6 {
  @include type-layout(xs, 1);
  @include margin-top(1, xs);
  @include margin-bottom(1, xs);
}
