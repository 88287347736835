.page-home {
  .pane-fsdf-theme {
    ul {
      @include clearfix();
      margin: 0 -10px;
      width: 100%;
      padding: 0;

      li {
        list-style: none;
        float: left;
        width: 20%;
      }

      .view-homepage-theme-link {
        margin: 10px;
        display: block;
        font-size: 36px;
        background: white;
        border: color(blue) solid 2px;
        padding: 20px;
        text-align: center;
        border-radius: 2px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, .07);
        transition: all ease-in-out .1s;
        position: relative;
        top: 0;

        &:visited {
          color: #397eb4;
        }

        &:hover,
        &:focus {
          box-shadow: 0 23px 30px -25px rgba(0, 0, 0, .5);
          border: 2px solid #b2af39;
          top: -3px;
          text-decoration: none;
          color: #b2af39;
        }
      }
    }
  }
}
