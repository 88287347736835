.panels-flexible-region-3-scrollspy {
  margin-top: -90px;

  h2 {
    @extend %h4;
    color: color(brown-dark);
    background-color: color(text-bg);
    display: table;
    padding: 0 17px;
    font-family: $source-sans;
  }

  ul {
    list-style: none;
    margin: 10px 0 0;

    li {
      margin-bottom: 10px;

      &.active {

        a {
          font-weight: bold;
          color: black;
          padding-left: 20px;
          border-left: 1px solid;
          margin-left: -20px;          
        }
      }
    }
  }
}