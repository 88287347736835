
.status-label {
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  color: white;
  letter-spacing: 1px;
  border-radius: 2px;  
  min-width: 50px;
  padding: 0;
  display: inline-block;     
}


// Traffic light reporting status colours

.status-green {
  .field-name-field-status {
    .field-item.even {
      background-color: color(green);
      @extend .status-label;       
    }
  }
}

.status-amber {
  .field-name-field-status {
    .field-item.even {
      background-color: color(amber);
      @extend .status-label;         
    }
  }
}

.status-red {
  .field-name-field-status {
    .field-item.even {
      background-color: color(red);
      @extend .status-label;         
    }
  }
}

.status-bar-wrapper {
  height: 30px;

  .status {
    float: left;
    padding: 0 10px;
    border-radius: 3px;
    color: #fff;
    //height: 100%;
    line-height: 1.7;
    text-transform: uppercase;
  }

  .progressbar-formatter {
    float: left;
    width: 300px;
  }

  .progressbar {
    height: 2px;
    width: 250px;
    margin: 10px 0;
    overflow: visible;
    border-left: medium none;
    border-right: medium none;
  }

  .ui-progressbar-value {
    border: medium none;
    border-radius: 0;
    height: 10px;
    margin: -5px 0 0;
  }

  .progressbar-label {
    position: relative;
    text-shadow: none;
    left: auto;
    top: auto;
    float: right;
    width: 40px;
    margin-top: -2px;
  }

  &.status-green {
    .status {
      background-color: color(green);
      @extend .status-label;    
    }  

    .ui-progressbar-value {
      background: color(green);
    }
  }

  &.status-amber {
    .status {
      background-color: color(amber);
      @extend .status-label;    
    }  

    .ui-progressbar-value {
      background: color(amber);
    }
  }

  &.status-red {
    .status {
      background-color: color(red);
      @extend .status-label;    
    }  

    .ui-progressbar-value {
      background: color(red);
    }
  }

  &.status-not-started {
    .status {
      background-color: color('grey');
      @extend .status-label;
      padding: 0 8px;   
    }  

    .ui-progressbar-value {
      background: color('grey');
    }
  }

  &.status-completed {
    .status {
      background-color: color(blue-dark);
      @extend .status-label;
      padding: 0 8px;   
    }  

    .ui-progressbar-value {
      background: color(blue-dark);
    }
  }     
}
