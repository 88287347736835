.node-type-program-activity {
	.layout-3col {
		margin-top: -100px;		
	}
}

.view-header-tasks {
	text-align: center;

	.tasks-title {
		@extend %h2;
	}

	a {
	    border: 1px solid;
	    padding: 15px 30px;
	    box-shadow: inset 0 2px 0 #ffffff;
	    background-color: #f5f7f8;
	    border-radius: 2px;

	    span {
		    font-weight: bold;
		    font-size: larger;
		    padding: 4px;	    	
	    }

	    &:hover,
	    &:focus {
		    box-shadow: inset 0 1px 0px #83bdec;
		    background-color: color(blue);
		    color: white;
		    border-color: #07c;
		    text-decoration: none;  	
	    }	
	}
}


.node-type-program-activity,
.page-dataset-tasks {

	.program-activity-prefix {
		@extend %h1;
	}

	h1 {
		font-family: $source-sans;
		letter-spacing: 0;
		text-transform: none;
		text-align: left;
		line-height: 47px;
		font-size: 40px;
		font-weight: normal;

		span {
			@extend %h2;
		}
	}

	.node-program-activity,
	.view-datasets {
		background: url("../img/title-bg.jpg") 0 bottom repeat-x;
		padding-bottom: 25px;

		.group-left {
			width: 150px;
		    margin-right: 30px;

		    .field {
				border-bottom: 1px solid #bfbfbf;
			    padding-bottom: 10px;		    	

		    	&.field-name-field-work-package {
			    	.field-label {
			    		margin-top: 0;
			    	}
		    	}

		    	&.field-name-field-theme-working-group,
		    	&.field-name-field-delivery-timeframe {
		    		border-bottom: none;
		    	}
		    }			
		}
		
		.group-right {
			width: 500px;
		}		
	}
	
	.view-tasks {
		.view-header {
		    @extend %h3;
			//margin-top: -22px;
		    //background-color: color(text-bg);
		    //display: table;
		    //padding: 0 15px 0 0;		    		    
		    
		    span {
		      font-weight: bold;
		    }
		}

		.view-content {
			.item-list {
				ul {
					padding: 0;

				    li {
						background-color: white;
					    padding: 15px;
					    margin-bottom: 15px;
					    border-radius: 4px;
					    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
					    width: 690px;				    	

				    	.node-tasks {
				    		.field-name-title {
								.title-prefix {
									@extend %h4;
				          			margin: 0;					
								}

								h2 {
									@extend %h3;
									margin-top: 0;
		          					font-size: 24px;
		          					font-family: $source-sans;
		          					text-transform: none;
		          					letter-spacing: 0;
								}
				    		}	
				    	}			    	
				    }				
				}
			}
		}	
	}		
}
