body {
  min-width: 1300px;
}

.body-content {
  //padding-top: 100px;
  padding-bottom: 100px;

  .layout-3col {
    max-width: 720px;
    margin: 0 auto;
  }
}

// Home page styling.
.front {
  .body-content {
    max-width: none;
    width: 100%;
    padding: 0;

    .layout-3col {
      .layout-3col__full {
        h1 {
          max-width: 810px;
          margin: 0 auto;
        }

        .field-name-field-content {
          max-width: 100%;
          margin: 0 auto;
        }
      }
      max-width: none;
      padding-bottom: 100px;

      .panel-display {
        @include respond-to(xxl) {
          overflow: initial;
        }
        margin: 0 auto;
        width: 1285px;
        padding: 0;
        text-align: center;
        overflow: hidden;

        .panel-col-first .action-link,
        .panel-col-last .action-link {
          padding: 50px;
          border: 1px solid #fff;
          margin: 0 1%;
          display: block;
          transition: all .15s ease-in-out;
          position: relative;
          top: 0;

          &:after {
            content: ' ';
            width: 150px;
            height: 1px;
            position: absolute;
            display: block;
            opacity: 0;
            transition: all .15s ease-in-out;
          }

          &:hover,
          &:focus {
            text-decoration: none;
            box-shadow: 0 3px 5px rgba(0, 0, 0, .3);
            top: -3px;

            &:after {
              opacity: 1;
            }
          }

          .content {
            @extend %h3;
            font-size: 36px;
            color: white;

            .field-name-field-action {
              font-size: 20px;
              font-family: source-sans-pro;
              font-weight: 600;
              text-transform: initial;
              letter-spacing: 0;
              color: #5594fb;
            }
          }
        }

        .panel-col-first .action-link {
          background: url('../img/bg-blue.jpg');
          background-repeat: no-repeat;
          background-size: cover;

          &:after {
            top: 50%;
            right: 100%;
            margin-right: -100px;
            background: #3f7ad8;
          }

          &:hover,
          &:focus {
            &:after {
              margin-right: -75px;
            }
          }
        }

        .panel-col-last .action-link {
          background: url('../img/bg-green.jpg');
          background-repeat: no-repeat;
          background-size: cover;

          .content {
            .field-name-field-action {
              color: #d5d217;
            }
          }

          &:after {
            top: 50%;
            left: 100%;
            margin-left: -100px;
            background: #d7d214;
          }

          &:hover,
          &:focus {
            &:after {
              margin-left: -75px;
            }
          }
        }
      }
    }
  }
}

.section-fsdf-dataset {
  .body-content {
    padding: 0;
    margin-top: -23px;

    .layout-3col {
      padding-top: 23px;
    }
  }
}

.section-graphs .layout-3col__right-content .block__title {
  text-align: center;
}

// National and input dataset styling.
.section-fsdf-theme,
.section-fsdf-dataset,
.section-dataset,
.section-agency,
.section-graphs,
.section-graphs-tables {
  .col-left,
  .col-right {
    h2 {
      color: color(heading);
    }
  }

  // input/output lines either side of National dataset type.
  .dataset-type-title {
    color: color(heading);
    text-align: center;

    &:after {
      height: 55px;
      width: 1px;
      margin-right: 16px;
      content: ' ';
      background-color: color(brown-caramel);
      display: inline-block;
    }

    h2 {
      position: relative;

      &:before {
        position: absolute;
        content: ' ';
        background-image: url('../img/line-dataset.png');
        background-repeat: no-repeat;
        background-position: left center;
        left: -100px;
        top: 0;
        bottom: 0;
        width: 320px;
      }

      &:after {
        position: absolute;
        content: ' ';
        background-image: url('../img/line-dataset.png');
        background-repeat: no-repeat;
        background-position: right center;
        right: -80px;
        top: 0;
        bottom: 0;
        width: 300px;
      }
    }
  }

  // input content.
  .col-left,
  .layout-3col__left-sidebar {
    width: 215px;
    float: left;
    margin-left: -290px;
    clear: none;
    margin-right: auto;
    padding: 0;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-image: none;
        list-style: none;
      }
    }
  }

  // middle content.
  .col-middle,
  .layout-3col__right-content {
    width: 720px;
    float: left;
    clear: none;
    margin-left: auto;

    .panels-flexible-row-first {
      text-align: center;

      .pane-node-field-theme-icon {
        display: inline-block;
        margin: 0 20px;

        .field-name-field-theme-icon {
          float: none;
          background: white;
          border-radius: 50%;
          border: 2px solid;
        }
      }

      .pane-page-title {
        display: inline-block;

        h1 {
          margin: 0 0 20px;
        }
      }
    }
  }

  .col-middle-top {
    display: inline-block;
    width: 720px;
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #bfbfbf;
  }

  .col-middle-bottom,
  .col-middle-footer {
    text-align: center;
    margin-bottom: 50px;
    padding-bottom: 50px;
    border-bottom: 1px solid #bfbfbf;

    h2 {
      margin-top: 0;
    }
  }

  // output content.
  .col-right {
    width: 190px;
    margin-right: -290px;
    float: right;

    ul {
      margin: 0;
      padding: 0;

      li {
        list-style-image: none;
        list-style: none;
      }
    }
  }
}

.section-fsdf-dataset .col-right h2,
.section-dataset .col-right h2 {
  width: 200px;
}

// Input/output lines either side of dataset type.
.dataset-type-output {
  .layout-swap {
    padding-top: 122px;
  }

  .dataset-type-title {
    h2 {
      &:before {
        width: 310px;
        left: -80px;
      }

      &:after {
        background-image: none;
      }
    }
  }
}

.dataset-type-input {
  .layout-swap {
    padding-top: 122px;
  }

  .dataset-type-title {
    h2 {
      &:before {
        background-image: none;
      }
    }
  }
}

//Single column content at the bottom of dataset pages
.content-bottom {
  max-width: 500px;
  margin: auto;
  float: none;
}

//home page announcement/updates feeds

.front {

  #views-announcement-list,
  #views-updates {
    width: 43%;
    margin: 100px 3% 0px 3%;
    .views-row {
      margin-bottom: 30px;
      text-align: left;
    }
  }

  #views-announcement-list {
    float: left;
    .views-row {
      overflow: hidden;
      clear: both;
      border-bottom: 1px solid #8D8601;
      .views-field-field-announcement-date {
        float: left;
        width: 30%;
        .date-display-single {
          text-transform: uppercase;
          letter-spacing: 1px;
          font-weight: bold;
          color: color(brown-caramel);
        }
      }
      .views-field-title {
        float: right;
        width: 70%;
        font-weight: bold;
      }
      .views-field-body {
       
        float: right;
        width: 70%;
        p {
          margin: 5px 0px 5px 0px;
        }
      }


    }
  }

  #views-updates {
    float: right;
    .views-row {
      overflow: hidden;
      clear: both;
      border-bottom: 1px solid #8D8601;
      padding-bottom: 10px;
      .views-field-created {
        float: left;
        width: 30%;
          .field-content {
            text-transform: uppercase;
            letter-spacing: 1px;
            font-weight: bold;
            color: color(brown-caramel);
          }
      }
      .views-field-title {
        float: right;
        width: 70%;
        font-weight: bold;
      } 
      .views-field-body {
        float: right;
        width: 70%;
      }
    }



  }

  #views-updates + * {
    clear: both;
  }

  #views-announcement-list h2.pane-title.pane__title:before,
  #views-updates h2.pane-title.pane__title:before {
    content: " ";
    height: 0;
    width: 0;
    margin: 0;
  }
  


  

} 