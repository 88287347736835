.section-dataset {
  .view-datasets {
    width: 720px;
    .view-content {
      .item-list {
        text-align: center;
        margin-bottom: 50px;
        padding-bottom: 50px;
        border-bottom: 1px solid #bfbfbf;

        h3 {
          @extend %h2;
          margin-top: 0;
        }

        ul {
          width: 70%;
          margin: auto;
          padding: 0;

          li {
            padding-top: 15px;
            margin-top: 15px;
            border-top: 1px solid rgba(191, 191, 191, 0.5);
            text-align: left;   

            &:first-of-type {
              border-top: none;
              margin-top: inherit;
              padding-top: inherit;
            }

            .collapsible {
              border: none;

              .fieldset-legend {
                a {
                  min-width: 457px;
                  font-size: 18px;
                  padding-left: 0;
                }

                span {
                  display: none;
                }                            
              }
              
              .fieldset-wrapper {
                .field-type-text-with-summary {
                  .field-label {
                    margin-top: 0;
                  }                
                }
              }
            }                    
          }       
        }      
      }
    }
  }
}