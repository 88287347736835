.page-user,
.page-user-login {
  .body-content {
    margin: 0 auto;
    width: 1285px;
    .layout-3col {
      max-width: none;
    }
  }
  main {
    margin: 0;
    width: 100%;
    .region-highlighted {
      text-align: center;
      max-width: 720px;
      margin: 0 auto;
    }
  }
  h1 {
    @extend %h2;
    color: color(heading);
  }
  h2 {
    @extend %h1;
  }
  .layout-3col__left-sidebar,
  .login-wrapper {
    margin: 0;
    width: 48%;
  }
  .layout-3col__left-sidebar {
    float: left;
    @include margin-top(1, l);
    background: white;
    padding: 1rem;
  }
  .login-wrapper {
    float: right;
  }
  input {
    padding-left: 15px;
    padding-right: 230px;
    margin: 5px auto;
    width: 100%;
    height: 54px;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    font-size: 20px;
  }
  .form-actions {
    float: left;
    margin: 0;
  }
  .form-submit {
    cursor: pointer;
    padding: 15px;
    border-radius: 5px;
    background: #007ee5;
    transition: all ease-out 0.2s;
    &:hover,
    &:focus,
    &:active {
      background: #b2af39;
      transition: 0s;
    }
  }
  #block-bean-login-page-header .entity-bean {
    margin-bottom: 40px;
    &::before {
      height: 55px;
      width: 1px;
      content: ' ';
      background-color: color(brown-caramel);
      display: block;
      margin: 0 auto 20px;
    }
  }
}
