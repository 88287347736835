/* Search Pages Design */
.search-api-page {

  /* Facet Section Titles */
  h2.facet-sec-title {
    color: #7a3b22;
    text-align: left;
    margin-bottom: 5px;
  }
  .layout-3col {
    max-width: none;
  }
  .panels-flexible-column-first {
    max-width: 240px;
    margin-top: 100px;
  }

  /* Search results column */
  .panels-flexible-column-last {
    .views-exposed-form .views-exposed-widget {
      float: none;
    }

    /* Search fulltext input */
    & .search-api-fulltext {
      position: relative;
      margin: 10px;
      & .views-widget-filter-search_api_views_fulltext {
        padding-right: 0px;
        padding-bottom: 30px;
        margin-bottom: 10px;
        border-bottom: 1px solid #c4c4c4;
      }
      & .views-widget-sort-by {
        float: right;
        padding: 0px;
        label {
          display: inline;
        }
        .select2-container {
          width: 135px !important;
          & .select2-choice {
            background: #fff;
            border-radius: 2px;
            -webkit-border-radius: 2px;
            -moz-border-radius: 2px;
            -o-border-radius: 2px;
            border: 1px solid #c4c4c4;
            height: 35px;
            line-height: 33px;
            padding-left: 9px;
          }
          & .select2-arrow {
            background: none;
            border: none;
            border-radius: 0px;
            b {
              background: url(../img/icon-g-arrow-down.png) no-repeat 0 14px;
            }
          }
          &.select2-dropdown-open {
            & .select2-arrow {
              b {
                background: url(../img/icon-g-arrow-up.png) no-repeat 0 14px;
              }
            }
          }
        }
      }
      & .views-widget-sort-order {
        padding: 0px;
      }
      & .form-item-search-api-views-fulltext {
        position: relative;
        input {
          padding-left: 15px;
          padding-right: 230px;
          width: 100%;
          height: 54px;
          border-radius: 5px;
          border: 1px solid #c4c4c4;
          font-size: 20px;
        }
        & .description {
          & .dropdown {
            position: absolute;
            top: 8px;
            right: 75px;
            background-color: #fff;
            & .current-dd-page {
              border-left: 1px dotted #c4c4c4;
            }
          }
          & label.search-submit {
            cursor: pointer;
            position: absolute;
            right: 0px;
            top: 0px;
            height: 54px;
            width: 71px;
            border-radius: 0px 5px 5px 0px;
            background-color: #007ee5;
            background-image: url(../img/search-icon.png);
            background-position: center center;
            background-repeat: no-repeat;
            display: block;
            text-indent: -99999px;
            &:hover {
              background-color: #b2af39;
            }
          }
        }
      }
      & .views-submit-button {
        display: none;
      }
    }

    /* Search results block */
    & .pane-current-search-dataset-search-results,
    & .pane-current-search-task-search-results {
      margin: 10px;
      & .current-search-item-results {
        margin: 0px;
        & h2 {
          color: #7a3b22;
          margin: 0px 0px 15px 0px;
          .searched-text {
            font-family:"source-sans", Arial, Helvetica, "Nimbus Sans L", sans-serif;
            font-weight: normal;
          }
        }
      }
      & .current-search-item-active-items {
        & ul {
          padding: 0px;
          margin: 0px 0px 30px 0px;
          & li {
            display: inline-block;
            background-color: #007ee5;
            border-radius: 5px;
            padding-left: 10px;
            padding-right: 3px;
            margin: 2px 0px;
            font-size: 16px;
            color: #fff;
            a {
              position: relative;
              top: 2px;
              text-indent: -9999px;
              width: 20px;
              height: 20px;
              float: right;
              background-image: url(../img/icon-cross.png);
              background-position: center center;
              background-repeat: no-repeat;
            }
            &:hover {
              background-color: #b2af39;
            }
          }
        }
      }
    }
    & .panels-flexible-column-search_layout-main-inside {
      max-width: 700px;
      margin: 0 auto;
    }
  }

  /* Collapsible Facets design */
  .fsdf-facetapi-collapsible {
    padding: 5px 15px 5px 5px;
    margin-bottom: 10px;
    &.active {
      & h2.pane-title {
        background-image: url(../img/icon-arrow-down.png);
      }
    }
    & h2.pane-title {
      cursor: pointer;
      font-size: 16px;
      color: #007ee5;
      background-image: url(../img/icon-arrow-right.png);
      background-position: 95% center;
      background-repeat: no-repeat;
      margin: 0px;
      line-height: 20px;
      font-family:"source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
      font-weight: bold;
      letter-spacing: normal;
      & .items-count {
        color: #fff;
        background-color: #007ee5;
        border-radius: 25px;
        padding: 0px 5px;
        display: inline-block;
        color: white;
        font-weight: 300;
        font-size: 12px;
        line-height: 1em;
        width: 22px;
        text-align: center;
        position: relative;
        top: -2px;
        font-weight: 400;
      }
    }
    .facetapi-limit-link {
      margin-top: 15px;
      font-size: 16px;
      display: block;
    }
  }
  .fsdf-facetapi-pane {
    & p {
      margin: 0px;
      font-size: 16px;
    }
    ul {
      margin: 10px 0px 0px 0px;
      padding: 0px;
      li {
        display: block;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 8px;
        &.facet-active {
          background-color: #007ee5;
          border-radius: 5px;
          padding: 3px 3px 3px 10px;
          margin: 2px 0px;
          color: #fff;
          a.facetapi-active {
            position: relative;
            display: block;
            text-indent: -9999px;
            width: 20px;
            height: 20px;
            float: right;
            background-image: url(../img/icon-cross.png);
            background-position: center center;
            background-repeat: no-repeat;
          }
          &:hover {
            background-color: #b2af39;
          }
        }
      }
    }
  }

  /* Search items design */
  .facet-api-search-items {
    margin-top: 20px;
    .views-row {
      max-width: 696px;
      height: 200px;
      margin: 10px 10px 20px 10px;
      display: block;
      background: white;
      border: #007ee5 solid 2px;
      padding: 20px;
      text-align: left;
      border-radius: 2px;
      box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
      -webkit-transition: all ease-in-out .1s;
      transition: all ease-in-out .1s;
      position: relative;
      top: 0;
      overflow: hidden;
      & .views-field-title {
        font-size: 30px;
        line-height: 30px;
        margin-bottom: 12px;
        & a {
          &:hover {
            text-decoration: none;
          }
          &:visited {
            color: #007ee5;
          }
        }
      }
      & .views-field-field-agency-juristiction {
        margin-right: 30px;
        max-width: 150px;
      }
      & .views-field-field-theme-working-group {
        max-width: 470px;
      }
      & .views-field-field-agency {
        max-width: 600px;
      }
      & .views-field-field-percentage-complete {
        margin-top: 5px;
      }
      & .views-field-field-agency-juristiction,
      & .views-field-field-theme-working-group,
      & .views-field-field-agency {
        float: left;
        & span {
          display: block;
          &.views-label {
            text-transform: uppercase;
            color: #7a3b22;
            font-weight: bold;
            font-size: 14px;
          }
        }
      }
      & .views-field-body {
        clear: both;
        padding-top: 10px;
        & p {
          margin: 0px;
        }
      }
      &:hover {
        box-shadow: 0 23px 30px -25px rgba(0, 0, 0, 0.5);
        border: 2px solid #b2af39;
        top: -3px;
        text-decoration: none;
        & .views-field-title {
          a {
            color: #b2af39;
          }
        }
      }
    }
  }
  #search-api-task {
    & .panels-flexible-column-last {
      & .facet-api-search-items {
        .views-row {
          height: 250px;
        }
      }
    }
  }


  /* Search Pager */
  ul.pager {
  	text-align: right;
  	.pager-first,
  	.pager-last,
  	.pager-next,
  	.pager-previous {
      text-transform: capitalize;
  	}
  }
}
