// Nav menu
//
// A "nav menu" is secondary, hierarchical navigation which can be displayed to the
// side or below the main content.
//
// Markup: nav-menu.twig
//
// Style guide: navigation.nav-menu

.nav-menu,
%nav-menu {

  &__item {
    list-style-image: image-url('navigation', 'nav-menu/leaf.svg');
    list-style-type: square;

    &.is-expanded,
    &--is-expanded {
      list-style-image: image-url('navigation', 'nav-menu/expanded.svg');
      list-style-type: circle;
    }

    &.is-collapsed,
    &--is-collapsed {
      list-style-image: image-url('navigation', 'nav-menu/collapsed.svg');
      list-style-type: disc;

      @include rtl() {
        list-style-image: image-url('navigation', 'nav-menu/collapsed-rtl.svg');
      }
    }
  }

  &__link {
    &.is-active,
    &--is-active {
      color: color(menu-active);
    }
  }
}

//
// Drupal selectors.
//

.menu__item {
  @extend %nav-menu__item;

  &.is-expanded {
    @extend %nav-menu__item--is-expanded;
  }

  &.is-collapsed {
    @extend %nav-menu__item--is-collapsed;
  }
}

// The active item in a Drupal menu.
.menu a.active {
  @extend %nav-menu__link--is-active;
}

.view-grouping {
  padding: 0;
  border: 0;
  margin: 0 0 20px;

  .view-grouping-header {
    @extend %h4;
    display: block;
    width: 100%;
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 10px;
    font-weight: 600;
    color: #376e97;
  }

  .group-count {
    background-color: color(blue);
    border-radius: 25px;
    padding: 0 5px;
    display: inline-block;
    color: white;
    font-weight: 300;
    font-size: 12px;
    line-height: 1em;
    position: relative;
    top: -2px;
  }

  .item-list {
    .view-grouping {
      margin-bottom: 0;

      .view-grouping-header {
        font-size: 15px;
        padding-left: 10px;
        letter-spacing: 0;
        text-transform: inherit;
      }
    }
  }

  .view-grouping-content {
    ul {
      padding: 0;
      margin: 0;
      list-style: none;

      li {
        font-size: 16px;
        line-height: 18px;
        padding: 0 0 10px 10px;

        &:last-of-type {
          margin-bottom: 20px;
        }
      }
    }
  }
}

.col-right {
  .field-items {
    .even,
    .odd {
      padding-bottom: 10px;
      font-size: 16px;
      line-height: 18px;
    }
  }
}
