.page-tranche-report {
    span.tranches-title {
      @extend %h3;
      font-family: "source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
      text-transform: initial;
      letter-spacing: 0;
      font-size: 40px;    
    }
}
.node-tranches,
.node-work-packages.node-teaser {
  background: url("../img/title-bg.jpg") 0 bottom repeat-x;
  padding-bottom: 25px;

  .field-name-field-wpid {
    @extend %h2;                    
  }
}

// Tranche report page styling

.page-tranche-report, .page-tranche {
  .layout-3col {
    max-width: 960px;

    .view-tranche-report {

      .field-name-field-status,
      .field-name-field-tranche-description {
        max-width: 332px;
        margin-right: 15px;
        display: inline-block;
        float: left;
      }
      .field-name-field-tranche-description {
        clear: left;
      }

      .field-name-field-status-comment {
          width: 500px;
          margin-right: 15px;
          display: block;
          float: right;
          margin-top: -90px;
          clear: both;   
      }
      .field-name-field-work-package {
        .odd, .even {
          float: left;
          font-family: $source-sans;
          font-size: 14px;

          .node-work-packages.view-mode-compact {
            background-color: white;
            padding: 15px;
            border-radius: 4px;
            max-width: 157px;
            height: 180px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
            text-align: center;
            display: inline-block;
            margin: 0 30px 15px 0;
            position: relative;

            .field-name-field-wpid {
              @extend %h4;
              font-size: 14px !important;
              margin-top: 0;                                    
            }

            .field-name-field-status {
              .field-item {
                padding: 4px;
                position: absolute;
                right: 0;
                margin-top: -6px;
                margin-right: -6px;
                font-size: 12px;
                max-width: 60px;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;                                     
              }            
            }

            a {
              font-size: 18px;
              line-height: 22px;
              overflow: hidden;
              display: -webkit-box;
              -webkit-line-clamp: 5;
              -webkit-box-orient: vertical;              
            }

            &.status-not-started {
              .field-name-field-status {
                .field-item {
                  background-color: color('grey');                  
                }
              }
            }

            &.status-amber {
              .field-name-field-status {
                .field-item {
                  background-color: color(amber);                  
                }
              }
            }

            &.status-red {
              .field-name-field-status {
                .field-item {
                  background-color: color(red);                  
                }
              }
            }

            &.status-completed {
              .field-name-field-status {
                .field-item {
                  background-color: color(blue-dark);                  
                }
              }
            }                                                             
          }
        }
      }
    }
  }     
}

// Work program page styling

.page-tranche {
  h1 {
    text-align: left;
  }
  
  .node-tranches.view-mode-compact {
      background: none;

      .field-name-field-trid {
        @extend %h4;
        color: color(brown-dark)
      }
      h3 {
        margin-top: 0;
      }
      .field-item {
        margin-bottom: 0;
      }
    }
}

// Work activities

.view-activities {
  .view-header-activities {
    @extend %h3;
    span {
      font-weight: bold;
    }
  }

  ul {
    padding: 0;
    
    li {

      .node-program-activity {
        background-color: white;
        padding: 15px 15px 30px;
        margin-bottom: 15px;
        border-radius: 4px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
        width: 690px;

        .group-left {
          width: 530px;
          margin-right: 30px;

          .field-name-field-paid {
            @extend %h4;
            margin-top: 0;
          }
          .field-name-title h3 {
            margin-top: 0;
            font-size: 24px;
          }

          .field-name-field-percentage-complete {
            margin-bottom: 10px;

            .field-label {
              float: left;
              margin-top: 0;
            }
          }

          fieldset.collapsible {
            border: none;
            padding: 0;
            margin: 0;

            .field-label {
              margin-top: 0;
            }        
          }  

          .fieldset-title {
            @extend %h4;
            color: color(blue);
            padding-right: 25px;
          }
        }
        .group-right {
          width: 80px;

          .field-name-field-start-timeframe {
            border-bottom: 1px solid #bfbfbf;
            padding-bottom: 15px;

            .field-label {
              margin-top: 0;
            }
          }
        } 
      }      
    }
    //&.contextual-links {
      //li {
        //width: initial !important;
      //}
    //}    
  }
}
