* {
  box-sizing: border-box;
}

html {
  font-size: 112.5%;
  line-height: 1.44444em;
  font-family: "source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  min-height: 100%;
}

@media print {
  html {
    font-size: 12pt;
  }
}

body {
  margin: 0;
  padding: 0;
  color: #412d36;
  background-color: #f5f5f5;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
progress,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

progress {
  vertical-align: baseline;
}

template,
[hidden] {
  display: none;
}

a {
  color: #007ee5;
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  transition: all ease-in-out .1s;
}

.col-middle-top a {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

:visited {
  color: #00467f;
}

a:hover,
a:focus {
  text-decoration: underline;
  transition: all ease-in-out .1s;
}

a:active {
  color: #d10000;
}

a:active:not(.button) {
  background-color: transparent;
}

a:active,
a:hover {
  outline-width: 0;
  transition: all ease-in-out .1s;
}

@media print {
  :link,
  :visited {
    text-decoration: underline;
  }
  a[href]:after {
    content: " (" attr(href) ")";
    font-weight: normal;
    font-size: 18px;
    text-decoration: none;
  }
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

.parent-btn-wrapper {
  background-image: url("../img/line-theme.png");
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 55px;
  position: relative;
  z-index: 10;
}

.parent-btn-wrapper .back-to-parent {
  background: #224272;
  border-radius: 50px;
  margin: auto;
  color: white;
  text-decoration: none;
  display: table;
  color: white;
  text-align: center;
  border-bottom: 2px solid #101e2f;
  border-top: 1px solid #839099;
  -webkit-box-shadow: 0 10px 70px -10px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 70px -10px rgba(0, 0, 0, 0.5);
  -o-box-shadow: 0 10px 70px -10px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 70px -10px rgba(0, 0, 0, 0.5);
}

.parent-btn-wrapper .back-to-parent:before {
  content: '';
  display: table;
}

.parent-btn-wrapper .back-to-parent:after {
  content: '';
  display: table;
  clear: both;
}

.parent-btn-wrapper .back-to-parent .button-icon {
  display: block;
  float: left;
  padding: 8px 30px 0;
}

.parent-btn-wrapper .back-to-parent .button-icon img {
  width: 50px;
  display: block;
}

.parent-btn-wrapper .back-to-parent .button-text {
  float: left;
  padding: 20px 100px 20px 30px;
  position: relative;
}

.parent-btn-wrapper .back-to-parent .button-text::after {
  position: absolute;
  top: 50%;
  margin-top: -10px;
  right: 25px;
  content: ' ';
  width: 20px;
  height: 20px;
  background-image: url("../img/icon-up-arrow.png");
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: center;
}

.parent-btn-wrapper .back-to-parent.active {
  background: #3c588c;
}

.parent-btn-wrapper .back-to-parent:hover, .parent-btn-wrapper .back-to-parent:focus {
  background: #3c588c;
  transition: all ease-in-out .1s;
}

.field-item {
  margin-bottom: 10px;
}

.logged-in .views-field-edit-node {
  padding: 3px 8px;
  background-color: #007ee5;
  float: right;
  border-radius: 5px;
}

.logged-in .views-field-edit-node a {
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.logged-in .view-fsdf-dataset .views-field-title,
.logged-in .view-agencies .views-field-title {
  display: inline-block;
}

.logged-in th.views-field-edit-node {
  display: none;
}

.logged-in a.edit-link,
.logged-in a.add-link {
  display: inline-block;
  padding: 3px 8px;
  background-color: #007ee5;
  float: right;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.logged-in .view-agencies {
  position: relative;
}

.logged-in .view-agencies a.add-link {
  display: block;
  position: absolute;
  top: -60px;
  right: 0;
}

/* Dataset / use cases */
.logged-in .view-display-id-block_2 li.views-row {
  position: relative;
}

.logged-in .view-display-id-block_2 li.views-row .views-field-edit-node {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
}

h1,
.page-user h2,
.page-user-login h2,
.header__site-name,
.node-type-program-activity .program-activity-prefix,
.page-dataset-tasks .program-activity-prefix {
  font-size: 3.88889rem;
  line-height: 2.88889rem;
  margin-top: 1.44444rem;
  margin-bottom: 1.44444rem;
  font-family: "korolev-compressed", Impact, "Arial Narrow", Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 10px;
  text-align: center;
  line-height: 67px;
}

.front h1 {
  max-width: 760px;
  margin: 0 auto;
}

h2,
.node-tranches .field-name-field-wpid,
.node-work-packages.node-teaser .field-name-field-wpid,
.page-user h1,
.page-user-login h1,
.section-dataset .view-datasets .view-content .item-list h3,
.view-header-tasks .tasks-title,
.node-type-program-activity h1 span,
.page-dataset-tasks h1 span {
  font-size: 1.55556rem;
  line-height: 2.16667rem;
  margin-top: 1.44444rem;
  margin-bottom: 1.44444rem;
  font-family: "korolev-compressed", Impact, "Arial Narrow", Helvetica, sans-serif;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.page-home h2, .pane-1 h2 {
  text-align: center;
  color: #7a3b22;
}

.page-home h2:before {
  height: 55px;
  width: 1px;
  content: ' ';
  background-color: #7a3b22;
  display: block;
  margin: 0 auto 20px;
}

h3,
.front .body-content .layout-3col .panel-display .panel-col-first .action-link .content,
.front .body-content .layout-3col .panel-display .panel-col-last .action-link .content,
.page-tranche-report span.tranches-title,
.view-activities .view-header-activities,
.node-type-program-activity .view-tasks .view-header,
.page-dataset-tasks .view-tasks .view-header,
.node-type-program-activity .view-tasks .view-content .item-list ul li .node-tasks .field-name-title h2,
.page-dataset-tasks .view-tasks .view-content .item-list ul li .node-tasks .field-name-title h2 {
  font-size: 1.55556rem;
  line-height: 2.16667rem;
  margin-top: 1.44444rem;
  margin-bottom: 1.44444rem;
  line-height: 32px;
  margin-bottom: 10px;
  font-weight: normal;
}

h4,
.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact .field-name-field-wpid,
.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact .field-name-field-wpid,
.page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact .field-name-field-wpid,
.page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact .field-name-field-wpid,
.page-tranche .node-tranches.view-mode-compact .field-name-field-trid,
.view-activities ul li .node-program-activity .group-left .field-name-field-paid,
.view-activities ul li .node-program-activity .group-left .fieldset-title,
.section-fsdf-theme .col-middle-top .panels-flexible-region-first h2,
.section-agency .col-middle-top .panels-flexible-region-first h2,
.col-middle-top .panels-flexible-region-first .pane-entity-field .field-label,
.field-label,
.panels-flexible-region-3-scrollspy h2,
.node-type-program-activity .view-tasks .view-content .item-list ul li .node-tasks .field-name-title .title-prefix,
.page-dataset-tasks .view-tasks .view-content .item-list ul li .node-tasks .field-name-title .title-prefix,
.page-tranche div.vertical-tabs ul.vertical-tabs-list li a,
.admin-menu div.vertical-tabs ul.vertical-tabs-list li a,
.view-grouping .view-grouping-header {
  font-size: 0.77778rem;
  line-height: 1.44444rem;
  margin-top: 1.44444rem;
  margin-bottom: 0;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: #7a3b22;
}

h5 {
  font-size: 1rem;
  line-height: 1.44444rem;
  margin-top: 1.44444rem;
  margin-bottom: 1.44444rem;
}

h6 {
  font-size: 0.77778rem;
  line-height: 1.44444rem;
  margin-top: 1.44444rem;
  margin-bottom: 1.44444rem;
}

abbr[title] {
  border-bottom: 0;
  text-decoration: underline;
  text-decoration: underline dotted;
}

@media print {
  abbr[title]:after {
    content: " (" attr(title) ")";
  }
}

b,
strong {
  font-weight: inherit;
}

strong,
b {
  font-weight: bolder;
}

pre,
code,
kbd,
samp,
var {
  font-family: Menlo, "DejaVu Sans Mono", "Ubuntu Mono", Courier, "Courier New", monospace, sans-serif;
  font-size: 1em;
}

dfn {
  font-style: italic;
}

mark {
  background-color: #fd0;
  color: #412d36;
}

small {
  font-size: 1rem;
}

sub,
sup {
  font-size: 0.77778rem;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

.divider,
hr {
  margin: 1.44444rem 0;
  border: 0;
  border-top: 1px solid #cccccc;
}

.divider > :first-child, hr > :first-child {
  margin-top: 1.44444rem;
}

blockquote {
  margin: 1.44444rem 2rem;
}

dl,
menu,
ol,
ul {
  margin: 1.44444rem 0;
}

ol ol,
ol ul,
ul ol,
ul ul {
  margin: 0;
}

dd {
  margin: 0 0 0 36px;
}

[dir="rtl"] dd {
  margin: 0 36px 0 0;
}

menu,
ol,
ul {
  padding: 0 0 0 36px;
}

[dir="rtl"] menu, [dir="rtl"]
ol, [dir="rtl"]
ul {
  padding: 0 36px 0 0;
}

figure {
  margin: 1.44444rem 0;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

p,
pre {
  margin: 1.44444rem 0;
}

img {
  border-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
}

svg:not(:root) {
  overflow: hidden;
}

button,
input,
optgroup,
select,
textarea {
  box-sizing: border-box;
  max-width: 100%;
  font: inherit;
  margin: 0;
}

button {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

input {
  overflow: visible;
}

[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
  height: auto;
}

[type='search'] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type='search']::-webkit-search-cancel-button, [type='search']::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

.button,
button,
html [type='button'],
[type='reset'],
[type='submit'] {
  -moz-appearance: button;
  -webkit-appearance: button;
}

.button,
button,
[type='button'],
[type='reset'],
[type='submit'] {
  display: inline-block;
  padding: 2px 6px;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
  color: #412d36;
}

.button::-moz-focus-inner,
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

.button:-moz-focusring,
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
  outline: 1px dotted ButtonText;
}

.button:hover, .button:focus, .button:active,
button:hover,
button:focus,
button:active,
[type='button']:hover,
[type='button']:focus,
[type='button']:active,
[type='reset']:hover,
[type='reset']:focus,
[type='reset']:active,
[type='submit']:hover,
[type='submit']:focus,
[type='submit']:active {
  text-decoration: none;
  color: #412d36;
}

[disabled].button,
button[disabled],
[disabled][type='button'],
[disabled][type='reset'],
[disabled][type='submit'] {
  cursor: default;
  color: #999999;
}

[disabled].button:hover,
button[disabled]:hover,
[disabled][type='button']:hover,
[disabled][type='reset']:hover,
[disabled][type='submit']:hover, [disabled].button:focus,
button[disabled]:focus,
[disabled][type='button']:focus,
[disabled][type='reset']:focus,
[disabled][type='submit']:focus, [disabled].button:active,
button[disabled]:active,
[disabled][type='button']:active,
[disabled][type='reset']:active,
[disabled][type='submit']:active {
  color: #999999;
}

[type='checkbox'],
[type='radio'] {
  box-sizing: border-box;
  padding: 0;
}

fieldset {
  padding: 0.50556rem 0.90278rem 0.93889rem;
  border: 1px solid #cccccc;
  margin: 0 2px;
  min-width: 0;
}

legend {
  box-sizing: border-box;
  display: table;
  max-width: 100%;
  white-space: normal;
  color: inherit;
  margin-left: -5px;
  padding: 0 5px;
}

label {
  display: block;
  font-weight: bold;
}

optgroup {
  font-weight: bold;
}

textarea {
  overflow: auto;
}

table {
  margin: 1.44444rem 0;
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

td,
th {
  padding: 5px 20px;
}

th {
  border-bottom: 2px solid #ccc;
}

.responsive-table {
  width: 100%;
  overflow-x: auto;
}

.layout-3col {
  margin-left: -20px;
  margin-right: -20px;
  padding-left: 0;
  padding-right: 0;
}

.layout-3col:before {
  content: "";
  display: table;
}

.layout-3col:after {
  content: "";
  display: table;
  clear: both;
}

.layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar, .layout-3col__col-1, .layout-3col__col-2, .layout-3col__col-3, .layout-3col__col-4, .layout-3col__col-x {
  clear: both;
  padding-left: 20px;
  padding-right: 20px;
  float: left;
  width: 100%;
  margin-left: 0%;
  margin-right: -100%;
}

[dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x {
  float: right;
  margin-right: 0%;
  margin-left: -100%;
}

@media (min-width: 777px) {
  .layout-3col {
    margin-left: -12px;
    margin-right: -13px;
    padding-left: 0;
    padding-right: 0;
  }
  .layout-3col:before {
    content: "";
    display: table;
  }
  .layout-3col:after {
    content: "";
    display: table;
    clear: both;
  }
  [dir="rtl"] .layout-3col {
    margin-left: -13px;
    margin-right: -12px;
  }
  .layout-3col__full, .layout-3col__left-content, .layout-3col__right-content, .layout-3col__left-sidebar, .layout-3col__right-sidebar {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__full, [dir="rtl"] .layout-3col__left-content, [dir="rtl"] .layout-3col__right-content, [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__right-sidebar {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__first-left-sidebar, .layout-3col__col-1, .layout-3col__col-3, .layout-3col__col-x:nth-child(2n + 1) {
    float: left;
    width: 50%;
    margin-left: 0%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(2n + 1) {
    padding-left: 13px;
    padding-right: 12px;
  }
  .layout-3col__second-left-sidebar, .layout-3col__col-2, .layout-3col__col-4, .layout-3col__col-x:nth-child(2n) {
    clear: none;
    float: left;
    width: 50%;
    margin-left: 50%;
    margin-right: -100%;
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    float: right;
    margin-right: 50%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__second-left-sidebar, [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-4, [dir="rtl"] .layout-3col__col-x:nth-child(2n) {
    padding-left: 13px;
    padding-right: 12px;
  }
}

@media (min-width: 999px) {
  .layout-3col__full {
    float: left;
    width: 100%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__full {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__left-content {
    float: left;
    width: 66.66667%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__left-content {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__right-content {
    float: left;
    width: 66.66667%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__right-content {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__left-sidebar, .layout-3col__first-left-sidebar, .layout-3col__second-left-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__left-sidebar, [dir="rtl"] .layout-3col__first-left-sidebar, [dir="rtl"] .layout-3col__second-left-sidebar {
    float: left;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  .layout-3col__right-sidebar {
    clear: right;
    float: right;
    width: 33.33333%;
    margin-right: 0%;
    margin-left: -100%;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    clear: left;
  }
  [dir="rtl"] .layout-3col__right-sidebar {
    float: left;
    margin-left: 0%;
    margin-right: -100%;
  }
  .layout-3col__col-1, .layout-3col__col-x:nth-child(3n+1) {
    clear: both;
    float: left;
    width: 33.33333%;
    margin-left: 0%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-1, [dir="rtl"] .layout-3col__col-x:nth-child(3n+1) {
    float: right;
    margin-right: 0%;
    margin-left: -100%;
  }
  .layout-3col__col-2, .layout-3col__col-x:nth-child(3n+2) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 33.33333%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-2, [dir="rtl"] .layout-3col__col-x:nth-child(3n+2) {
    float: right;
    margin-right: 33.33333%;
    margin-left: -100%;
  }
  .layout-3col__col-3, .layout-3col__col-x:nth-child(3n) {
    clear: none;
    float: left;
    width: 33.33333%;
    margin-left: 66.66667%;
    margin-right: -100%;
  }
  [dir="rtl"] .layout-3col__col-3, [dir="rtl"] .layout-3col__col-x:nth-child(3n) {
    float: right;
    margin-right: 66.66667%;
    margin-left: -100%;
  }
  .layout-3col__col-4 {
    display: none;
  }
}

.layout-3col__grid-item-container {
  padding-left: 0;
  padding-right: 0;
}

.layout-3col__grid-item-container:before {
  content: "";
  display: table;
}

.layout-3col__grid-item-container:after {
  content: "";
  display: table;
  clear: both;
}

.layout-center {
  padding-left: 20px;
  padding-right: 20px;
  margin: 0 auto;
  max-width: 1325px;
}

@media (min-width: 777px) {
  .layout-center {
    padding-left: 12px;
    padding-right: 13px;
  }
  [dir="rtl"] .layout-center {
    padding-left: 13px;
    padding-right: 12px;
  }
}

.layout-center--shared-grid,
.layout-center.layout-3col {
  padding-left: 0;
  padding-right: 0;
}

.layout-swap {
  position: relative;
}

@media (min-width: 555px) {
  .layout-swap {
    padding-top: 52px;
  }
}

@media (min-width: 555px) {
  .layout-swap__top {
    position: absolute;
    top: 0;
    height: 52px;
    width: 100%;
  }
}

body {
  min-width: 1300px;
}

.body-content {
  padding-bottom: 100px;
}

.body-content .layout-3col {
  max-width: 720px;
  margin: 0 auto;
}

.front .body-content {
  max-width: none;
  width: 100%;
  padding: 0;
}

.front .body-content .layout-3col {
  max-width: none;
  padding-bottom: 100px;
}

.front .body-content .layout-3col .layout-3col__full h1 {
  max-width: 810px;
  margin: 0 auto;
}

.front .body-content .layout-3col .layout-3col__full .field-name-field-content {
  max-width: 100%;
  margin: 0 auto;
}

.front .body-content .layout-3col .panel-display {
  margin: 0 auto;
  width: 1285px;
  padding: 0;
  text-align: center;
  overflow: hidden;
}

@media (min-width: 1365px) {
  .front .body-content .layout-3col .panel-display {
    overflow: initial;
  }
}

.front .body-content .layout-3col .panel-display .panel-col-first .action-link,
.front .body-content .layout-3col .panel-display .panel-col-last .action-link {
  padding: 50px;
  border: 1px solid #fff;
  margin: 0 1%;
  display: block;
  transition: all .15s ease-in-out;
  position: relative;
  top: 0;
}

.front .body-content .layout-3col .panel-display .panel-col-first .action-link:after,
.front .body-content .layout-3col .panel-display .panel-col-last .action-link:after {
  content: ' ';
  width: 150px;
  height: 1px;
  position: absolute;
  display: block;
  opacity: 0;
  transition: all .15s ease-in-out;
}

.front .body-content .layout-3col .panel-display .panel-col-first .action-link:hover, .front .body-content .layout-3col .panel-display .panel-col-first .action-link:focus,
.front .body-content .layout-3col .panel-display .panel-col-last .action-link:hover,
.front .body-content .layout-3col .panel-display .panel-col-last .action-link:focus {
  text-decoration: none;
  box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
  top: -3px;
}

.front .body-content .layout-3col .panel-display .panel-col-first .action-link:hover:after, .front .body-content .layout-3col .panel-display .panel-col-first .action-link:focus:after,
.front .body-content .layout-3col .panel-display .panel-col-last .action-link:hover:after,
.front .body-content .layout-3col .panel-display .panel-col-last .action-link:focus:after {
  opacity: 1;
}

.front .body-content .layout-3col .panel-display .panel-col-first .action-link .content,
.front .body-content .layout-3col .panel-display .panel-col-last .action-link .content {
  font-size: 36px;
  color: white;
}

.front .body-content .layout-3col .panel-display .panel-col-first .action-link .content .field-name-field-action,
.front .body-content .layout-3col .panel-display .panel-col-last .action-link .content .field-name-field-action {
  font-size: 20px;
  font-family: source-sans-pro;
  font-weight: 600;
  text-transform: initial;
  letter-spacing: 0;
  color: #5594fb;
}

.front .body-content .layout-3col .panel-display .panel-col-first .action-link {
  background: url("../img/bg-blue.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.front .body-content .layout-3col .panel-display .panel-col-first .action-link:after {
  top: 50%;
  right: 100%;
  margin-right: -100px;
  background: #3f7ad8;
}

.front .body-content .layout-3col .panel-display .panel-col-first .action-link:hover:after, .front .body-content .layout-3col .panel-display .panel-col-first .action-link:focus:after {
  margin-right: -75px;
}

.front .body-content .layout-3col .panel-display .panel-col-last .action-link {
  background: url("../img/bg-green.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}

.front .body-content .layout-3col .panel-display .panel-col-last .action-link .content .field-name-field-action {
  color: #d5d217;
}

.front .body-content .layout-3col .panel-display .panel-col-last .action-link:after {
  top: 50%;
  left: 100%;
  margin-left: -100px;
  background: #d7d214;
}

.front .body-content .layout-3col .panel-display .panel-col-last .action-link:hover:after, .front .body-content .layout-3col .panel-display .panel-col-last .action-link:focus:after {
  margin-left: -75px;
}

.section-fsdf-dataset .body-content {
  padding: 0;
  margin-top: -23px;
}

.section-fsdf-dataset .body-content .layout-3col {
  padding-top: 23px;
}

.section-graphs .layout-3col__right-content .block__title {
  text-align: center;
}

.section-fsdf-theme .col-left h2,
.section-fsdf-theme .col-right h2,
.section-fsdf-dataset .col-left h2,
.section-fsdf-dataset .col-right h2,
.section-dataset .col-left h2,
.section-dataset .col-right h2,
.section-agency .col-left h2,
.section-agency .col-right h2,
.section-graphs .col-left h2,
.section-graphs .col-right h2,
.section-graphs-tables .col-left h2,
.section-graphs-tables .col-right h2 {
  color: #7a3b22;
}

.section-fsdf-theme .dataset-type-title,
.section-fsdf-dataset .dataset-type-title,
.section-dataset .dataset-type-title,
.section-agency .dataset-type-title,
.section-graphs .dataset-type-title,
.section-graphs-tables .dataset-type-title {
  color: #7a3b22;
  text-align: center;
}

.section-fsdf-theme .dataset-type-title:after,
.section-fsdf-dataset .dataset-type-title:after,
.section-dataset .dataset-type-title:after,
.section-agency .dataset-type-title:after,
.section-graphs .dataset-type-title:after,
.section-graphs-tables .dataset-type-title:after {
  height: 55px;
  width: 1px;
  margin-right: 16px;
  content: ' ';
  background-color: #7a3b22;
  display: inline-block;
}

.section-fsdf-theme .dataset-type-title h2,
.section-fsdf-dataset .dataset-type-title h2,
.section-dataset .dataset-type-title h2,
.section-agency .dataset-type-title h2,
.section-graphs .dataset-type-title h2,
.section-graphs-tables .dataset-type-title h2 {
  position: relative;
}

.section-fsdf-theme .dataset-type-title h2:before,
.section-fsdf-dataset .dataset-type-title h2:before,
.section-dataset .dataset-type-title h2:before,
.section-agency .dataset-type-title h2:before,
.section-graphs .dataset-type-title h2:before,
.section-graphs-tables .dataset-type-title h2:before {
  position: absolute;
  content: ' ';
  background-image: url("../img/line-dataset.png");
  background-repeat: no-repeat;
  background-position: left center;
  left: -100px;
  top: 0;
  bottom: 0;
  width: 320px;
}

.section-fsdf-theme .dataset-type-title h2:after,
.section-fsdf-dataset .dataset-type-title h2:after,
.section-dataset .dataset-type-title h2:after,
.section-agency .dataset-type-title h2:after,
.section-graphs .dataset-type-title h2:after,
.section-graphs-tables .dataset-type-title h2:after {
  position: absolute;
  content: ' ';
  background-image: url("../img/line-dataset.png");
  background-repeat: no-repeat;
  background-position: right center;
  right: -80px;
  top: 0;
  bottom: 0;
  width: 300px;
}

.section-fsdf-theme .col-left,
.section-fsdf-theme .layout-3col__left-sidebar,
.section-fsdf-dataset .col-left,
.section-fsdf-dataset .layout-3col__left-sidebar,
.section-dataset .col-left,
.section-dataset .layout-3col__left-sidebar,
.section-agency .col-left,
.section-agency .layout-3col__left-sidebar,
.section-graphs .col-left,
.section-graphs .layout-3col__left-sidebar,
.section-graphs-tables .col-left,
.section-graphs-tables .layout-3col__left-sidebar {
  width: 215px;
  float: left;
  margin-left: -290px;
  clear: none;
  margin-right: auto;
  padding: 0;
}

.section-fsdf-theme .col-left ul,
.section-fsdf-theme .layout-3col__left-sidebar ul,
.section-fsdf-dataset .col-left ul,
.section-fsdf-dataset .layout-3col__left-sidebar ul,
.section-dataset .col-left ul,
.section-dataset .layout-3col__left-sidebar ul,
.section-agency .col-left ul,
.section-agency .layout-3col__left-sidebar ul,
.section-graphs .col-left ul,
.section-graphs .layout-3col__left-sidebar ul,
.section-graphs-tables .col-left ul,
.section-graphs-tables .layout-3col__left-sidebar ul {
  margin: 0;
  padding: 0;
}

.section-fsdf-theme .col-left ul li,
.section-fsdf-theme .layout-3col__left-sidebar ul li,
.section-fsdf-dataset .col-left ul li,
.section-fsdf-dataset .layout-3col__left-sidebar ul li,
.section-dataset .col-left ul li,
.section-dataset .layout-3col__left-sidebar ul li,
.section-agency .col-left ul li,
.section-agency .layout-3col__left-sidebar ul li,
.section-graphs .col-left ul li,
.section-graphs .layout-3col__left-sidebar ul li,
.section-graphs-tables .col-left ul li,
.section-graphs-tables .layout-3col__left-sidebar ul li {
  list-style-image: none;
  list-style: none;
}

.section-fsdf-theme .col-middle,
.section-fsdf-theme .layout-3col__right-content,
.section-fsdf-dataset .col-middle,
.section-fsdf-dataset .layout-3col__right-content,
.section-dataset .col-middle,
.section-dataset .layout-3col__right-content,
.section-agency .col-middle,
.section-agency .layout-3col__right-content,
.section-graphs .col-middle,
.section-graphs .layout-3col__right-content,
.section-graphs-tables .col-middle,
.section-graphs-tables .layout-3col__right-content {
  width: 720px;
  float: left;
  clear: none;
  margin-left: auto;
}

.section-fsdf-theme .col-middle .panels-flexible-row-first,
.section-fsdf-theme .layout-3col__right-content .panels-flexible-row-first,
.section-fsdf-dataset .col-middle .panels-flexible-row-first,
.section-fsdf-dataset .layout-3col__right-content .panels-flexible-row-first,
.section-dataset .col-middle .panels-flexible-row-first,
.section-dataset .layout-3col__right-content .panels-flexible-row-first,
.section-agency .col-middle .panels-flexible-row-first,
.section-agency .layout-3col__right-content .panels-flexible-row-first,
.section-graphs .col-middle .panels-flexible-row-first,
.section-graphs .layout-3col__right-content .panels-flexible-row-first,
.section-graphs-tables .col-middle .panels-flexible-row-first,
.section-graphs-tables .layout-3col__right-content .panels-flexible-row-first {
  text-align: center;
}

.section-fsdf-theme .col-middle .panels-flexible-row-first .pane-node-field-theme-icon,
.section-fsdf-theme .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon,
.section-fsdf-dataset .col-middle .panels-flexible-row-first .pane-node-field-theme-icon,
.section-fsdf-dataset .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon,
.section-dataset .col-middle .panels-flexible-row-first .pane-node-field-theme-icon,
.section-dataset .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon,
.section-agency .col-middle .panels-flexible-row-first .pane-node-field-theme-icon,
.section-agency .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon,
.section-graphs .col-middle .panels-flexible-row-first .pane-node-field-theme-icon,
.section-graphs .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon,
.section-graphs-tables .col-middle .panels-flexible-row-first .pane-node-field-theme-icon,
.section-graphs-tables .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon {
  display: inline-block;
  margin: 0 20px;
}

.section-fsdf-theme .col-middle .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-fsdf-theme .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-fsdf-dataset .col-middle .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-fsdf-dataset .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-dataset .col-middle .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-dataset .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-agency .col-middle .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-agency .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-graphs .col-middle .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-graphs .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-graphs-tables .col-middle .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon,
.section-graphs-tables .layout-3col__right-content .panels-flexible-row-first .pane-node-field-theme-icon .field-name-field-theme-icon {
  float: none;
  background: white;
  border-radius: 50%;
  border: 2px solid;
}

.section-fsdf-theme .col-middle .panels-flexible-row-first .pane-page-title,
.section-fsdf-theme .layout-3col__right-content .panels-flexible-row-first .pane-page-title,
.section-fsdf-dataset .col-middle .panels-flexible-row-first .pane-page-title,
.section-fsdf-dataset .layout-3col__right-content .panels-flexible-row-first .pane-page-title,
.section-dataset .col-middle .panels-flexible-row-first .pane-page-title,
.section-dataset .layout-3col__right-content .panels-flexible-row-first .pane-page-title,
.section-agency .col-middle .panels-flexible-row-first .pane-page-title,
.section-agency .layout-3col__right-content .panels-flexible-row-first .pane-page-title,
.section-graphs .col-middle .panels-flexible-row-first .pane-page-title,
.section-graphs .layout-3col__right-content .panels-flexible-row-first .pane-page-title,
.section-graphs-tables .col-middle .panels-flexible-row-first .pane-page-title,
.section-graphs-tables .layout-3col__right-content .panels-flexible-row-first .pane-page-title {
  display: inline-block;
}

.section-fsdf-theme .col-middle .panels-flexible-row-first .pane-page-title h1,
.section-fsdf-theme .layout-3col__right-content .panels-flexible-row-first .pane-page-title h1,
.section-fsdf-dataset .col-middle .panels-flexible-row-first .pane-page-title h1,
.section-fsdf-dataset .layout-3col__right-content .panels-flexible-row-first .pane-page-title h1,
.section-dataset .col-middle .panels-flexible-row-first .pane-page-title h1,
.section-dataset .layout-3col__right-content .panels-flexible-row-first .pane-page-title h1,
.section-agency .col-middle .panels-flexible-row-first .pane-page-title h1,
.section-agency .layout-3col__right-content .panels-flexible-row-first .pane-page-title h1,
.section-graphs .col-middle .panels-flexible-row-first .pane-page-title h1,
.section-graphs .layout-3col__right-content .panels-flexible-row-first .pane-page-title h1,
.section-graphs-tables .col-middle .panels-flexible-row-first .pane-page-title h1,
.section-graphs-tables .layout-3col__right-content .panels-flexible-row-first .pane-page-title h1 {
  margin: 0 0 20px;
}

.section-fsdf-theme .col-middle-top,
.section-fsdf-dataset .col-middle-top,
.section-dataset .col-middle-top,
.section-agency .col-middle-top,
.section-graphs .col-middle-top,
.section-graphs-tables .col-middle-top {
  display: inline-block;
  width: 720px;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #bfbfbf;
}

.section-fsdf-theme .col-middle-bottom,
.section-fsdf-theme .col-middle-footer,
.section-fsdf-dataset .col-middle-bottom,
.section-fsdf-dataset .col-middle-footer,
.section-dataset .col-middle-bottom,
.section-dataset .col-middle-footer,
.section-agency .col-middle-bottom,
.section-agency .col-middle-footer,
.section-graphs .col-middle-bottom,
.section-graphs .col-middle-footer,
.section-graphs-tables .col-middle-bottom,
.section-graphs-tables .col-middle-footer {
  text-align: center;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #bfbfbf;
}

.section-fsdf-theme .col-middle-bottom h2,
.section-fsdf-theme .col-middle-footer h2,
.section-fsdf-dataset .col-middle-bottom h2,
.section-fsdf-dataset .col-middle-footer h2,
.section-dataset .col-middle-bottom h2,
.section-dataset .col-middle-footer h2,
.section-agency .col-middle-bottom h2,
.section-agency .col-middle-footer h2,
.section-graphs .col-middle-bottom h2,
.section-graphs .col-middle-footer h2,
.section-graphs-tables .col-middle-bottom h2,
.section-graphs-tables .col-middle-footer h2 {
  margin-top: 0;
}

.section-fsdf-theme .col-right,
.section-fsdf-dataset .col-right,
.section-dataset .col-right,
.section-agency .col-right,
.section-graphs .col-right,
.section-graphs-tables .col-right {
  width: 190px;
  margin-right: -290px;
  float: right;
}

.section-fsdf-theme .col-right ul,
.section-fsdf-dataset .col-right ul,
.section-dataset .col-right ul,
.section-agency .col-right ul,
.section-graphs .col-right ul,
.section-graphs-tables .col-right ul {
  margin: 0;
  padding: 0;
}

.section-fsdf-theme .col-right ul li,
.section-fsdf-dataset .col-right ul li,
.section-dataset .col-right ul li,
.section-agency .col-right ul li,
.section-graphs .col-right ul li,
.section-graphs-tables .col-right ul li {
  list-style-image: none;
  list-style: none;
}

.section-fsdf-dataset .col-right h2,
.section-dataset .col-right h2 {
  width: 200px;
}

.dataset-type-output .layout-swap {
  padding-top: 122px;
}

.dataset-type-output .dataset-type-title h2:before {
  width: 310px;
  left: -80px;
}

.dataset-type-output .dataset-type-title h2:after {
  background-image: none;
}

.dataset-type-input .layout-swap {
  padding-top: 122px;
}

.dataset-type-input .dataset-type-title h2:before {
  background-image: none;
}

.content-bottom {
  max-width: 500px;
  margin: auto;
  float: none;
}

.front #views-announcement-list,
.front #views-updates {
  width: 43%;
  margin: 100px 3% 0px 3%;
}

.front #views-announcement-list .views-row,
.front #views-updates .views-row {
  margin-bottom: 30px;
  text-align: left;
}

.front #views-announcement-list {
  float: left;
}

.front #views-announcement-list .views-row {
  overflow: hidden;
  clear: both;
  border-bottom: 1px solid #8D8601;
}

.front #views-announcement-list .views-row .views-field-field-announcement-date {
  float: left;
  width: 30%;
}

.front #views-announcement-list .views-row .views-field-field-announcement-date .date-display-single {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: #7a3b22;
}

.front #views-announcement-list .views-row .views-field-title {
  float: right;
  width: 70%;
  font-weight: bold;
}

.front #views-announcement-list .views-row .views-field-body {
  float: right;
  width: 70%;
}

.front #views-announcement-list .views-row .views-field-body p {
  margin: 5px 0px 5px 0px;
}

.front #views-updates {
  float: right;
}

.front #views-updates .views-row {
  overflow: hidden;
  clear: both;
  border-bottom: 1px solid #8D8601;
  padding-bottom: 10px;
}

.front #views-updates .views-row .views-field-created {
  float: left;
  width: 30%;
}

.front #views-updates .views-row .views-field-created .field-content {
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: bold;
  color: #7a3b22;
}

.front #views-updates .views-row .views-field-title {
  float: right;
  width: 70%;
  font-weight: bold;
}

.front #views-updates .views-row .views-field-body {
  float: right;
  width: 70%;
}

.front #views-updates + * {
  clear: both;
}

.front #views-announcement-list h2.pane-title.pane__title:before,
.front #views-updates h2.pane-title.pane__title:before {
  content: " ";
  height: 0;
  width: 0;
  margin: 0;
}

.page-tranche-report span.tranches-title {
  font-family: "source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  text-transform: initial;
  letter-spacing: 0;
  font-size: 40px;
}

.node-tranches,
.node-work-packages.node-teaser {
  background: url("../img/title-bg.jpg") 0 bottom repeat-x;
  padding-bottom: 25px;
}

.page-tranche-report .layout-3col, .page-tranche .layout-3col {
  max-width: 960px;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-status,
.page-tranche-report .layout-3col .view-tranche-report .field-name-field-tranche-description, .page-tranche .layout-3col .view-tranche-report .field-name-field-status,
.page-tranche .layout-3col .view-tranche-report .field-name-field-tranche-description {
  max-width: 332px;
  margin-right: 15px;
  display: inline-block;
  float: left;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-tranche-description, .page-tranche .layout-3col .view-tranche-report .field-name-field-tranche-description {
  clear: left;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-status-comment, .page-tranche .layout-3col .view-tranche-report .field-name-field-status-comment {
  width: 500px;
  margin-right: 15px;
  display: block;
  float: right;
  margin-top: -90px;
  clear: both;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd, .page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even {
  float: left;
  font-family: "source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-size: 14px;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact, .page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact {
  background-color: white;
  padding: 15px;
  border-radius: 4px;
  max-width: 157px;
  height: 180px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  text-align: center;
  display: inline-block;
  margin: 0 30px 15px 0;
  position: relative;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact .field-name-field-wpid, .page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact .field-name-field-wpid, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact .field-name-field-wpid, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact .field-name-field-wpid {
  font-size: 14px !important;
  margin-top: 0;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact .field-name-field-status .field-item, .page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact .field-name-field-status .field-item {
  padding: 4px;
  position: absolute;
  right: 0;
  margin-top: -6px;
  margin-right: -6px;
  font-size: 12px;
  max-width: 60px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact a, .page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact a, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact a, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact a {
  font-size: 18px;
  line-height: 22px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact.status-not-started .field-name-field-status .field-item, .page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact.status-not-started .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact.status-not-started .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact.status-not-started .field-name-field-status .field-item {
  background-color: #999999;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact.status-amber .field-name-field-status .field-item, .page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact.status-amber .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact.status-amber .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact.status-amber .field-name-field-status .field-item {
  background-color: #e76d00;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact.status-red .field-name-field-status .field-item, .page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact.status-red .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact.status-red .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact.status-red .field-name-field-status .field-item {
  background-color: #d10000;
}

.page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact.status-completed .field-name-field-status .field-item, .page-tranche-report .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact.status-completed .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .odd .node-work-packages.view-mode-compact.status-completed .field-name-field-status .field-item, .page-tranche .layout-3col .view-tranche-report .field-name-field-work-package .even .node-work-packages.view-mode-compact.status-completed .field-name-field-status .field-item {
  background-color: #224272;
}

.page-tranche h1 {
  text-align: left;
}

.page-tranche .node-tranches.view-mode-compact {
  background: none;
}

.page-tranche .node-tranches.view-mode-compact .field-name-field-trid {
  color: #412d36;
}

.page-tranche .node-tranches.view-mode-compact h3 {
  margin-top: 0;
}

.page-tranche .node-tranches.view-mode-compact .field-item {
  margin-bottom: 0;
}

.view-activities .view-header-activities span {
  font-weight: bold;
}

.view-activities ul {
  padding: 0;
}

.view-activities ul li .node-program-activity {
  background-color: white;
  padding: 15px 15px 30px;
  margin-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  width: 690px;
}

.view-activities ul li .node-program-activity .group-left {
  width: 530px;
  margin-right: 30px;
}

.view-activities ul li .node-program-activity .group-left .field-name-field-paid {
  margin-top: 0;
}

.view-activities ul li .node-program-activity .group-left .field-name-title h3 {
  margin-top: 0;
  font-size: 24px;
}

.view-activities ul li .node-program-activity .group-left .field-name-field-percentage-complete {
  margin-bottom: 10px;
}

.view-activities ul li .node-program-activity .group-left .field-name-field-percentage-complete .field-label {
  float: left;
  margin-top: 0;
}

.view-activities ul li .node-program-activity .group-left fieldset.collapsible {
  border: none;
  padding: 0;
  margin: 0;
}

.view-activities ul li .node-program-activity .group-left fieldset.collapsible .field-label {
  margin-top: 0;
}

.view-activities ul li .node-program-activity .group-left .fieldset-title {
  color: #007ee5;
  padding-right: 25px;
}

.view-activities ul li .node-program-activity .group-right {
  width: 80px;
}

.view-activities ul li .node-program-activity .group-right .field-name-field-start-timeframe {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 15px;
}

.view-activities ul li .node-program-activity .group-right .field-name-field-start-timeframe .field-label {
  margin-top: 0;
}

.page-user .body-content,
.page-user-login .body-content {
  margin: 0 auto;
  width: 1285px;
}

.page-user .body-content .layout-3col,
.page-user-login .body-content .layout-3col {
  max-width: none;
}

.page-user main,
.page-user-login main {
  margin: 0;
  width: 100%;
}

.page-user main .region-highlighted,
.page-user-login main .region-highlighted {
  text-align: center;
  max-width: 720px;
  margin: 0 auto;
}

.page-user h1,
.page-user-login h1 {
  color: #7a3b22;
}

.page-user .layout-3col__left-sidebar,
.page-user .login-wrapper,
.page-user-login .layout-3col__left-sidebar,
.page-user-login .login-wrapper {
  margin: 0;
  width: 48%;
}

.page-user .layout-3col__left-sidebar,
.page-user-login .layout-3col__left-sidebar {
  float: left;
  margin-top: 1.44444rem;
  background: white;
  padding: 1rem;
}

.page-user .login-wrapper,
.page-user-login .login-wrapper {
  float: right;
}

.page-user input,
.page-user-login input {
  padding-left: 15px;
  padding-right: 230px;
  margin: 5px auto;
  width: 100%;
  height: 54px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  font-size: 20px;
}

.page-user .form-actions,
.page-user-login .form-actions {
  float: left;
  margin: 0;
}

.page-user .form-submit,
.page-user-login .form-submit {
  cursor: pointer;
  padding: 15px;
  border-radius: 5px;
  background: #007ee5;
  transition: all ease-out 0.2s;
}

.page-user .form-submit:hover, .page-user .form-submit:focus, .page-user .form-submit:active,
.page-user-login .form-submit:hover,
.page-user-login .form-submit:focus,
.page-user-login .form-submit:active {
  background: #b2af39;
  transition: 0s;
}

.page-user #block-bean-login-page-header .entity-bean,
.page-user-login #block-bean-login-page-header .entity-bean {
  margin-bottom: 40px;
}

.page-user #block-bean-login-page-header .entity-bean::before,
.page-user-login #block-bean-login-page-header .entity-bean::before {
  height: 55px;
  width: 1px;
  content: ' ';
  background-color: #7a3b22;
  display: block;
  margin: 0 auto 20px;
}

/* Search Pages Design */
.search-api-page {
  /* Facet Section Titles */
  /* Search results column */
  /* Collapsible Facets design */
  /* Search items design */
  /* Search Pager */
}

.search-api-page h2.facet-sec-title {
  color: #7a3b22;
  text-align: left;
  margin-bottom: 5px;
}

.search-api-page .layout-3col {
  max-width: none;
}

.search-api-page .panels-flexible-column-first {
  max-width: 240px;
  margin-top: 100px;
}

.search-api-page .panels-flexible-column-last {
  /* Search fulltext input */
  /* Search results block */
}

.search-api-page .panels-flexible-column-last .views-exposed-form .views-exposed-widget {
  float: none;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext {
  position: relative;
  margin: 10px;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-widget-filter-search_api_views_fulltext {
  padding-right: 0px;
  padding-bottom: 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c4c4c4;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-widget-sort-by {
  float: right;
  padding: 0px;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-widget-sort-by label {
  display: inline;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-widget-sort-by .select2-container {
  width: 135px !important;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-widget-sort-by .select2-container .select2-choice {
  background: #fff;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -o-border-radius: 2px;
  border: 1px solid #c4c4c4;
  height: 35px;
  line-height: 33px;
  padding-left: 9px;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-widget-sort-by .select2-container .select2-arrow {
  background: none;
  border: none;
  border-radius: 0px;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-widget-sort-by .select2-container .select2-arrow b {
  background: url(../img/icon-g-arrow-down.png) no-repeat 0 14px;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-widget-sort-by .select2-container.select2-dropdown-open .select2-arrow b {
  background: url(../img/icon-g-arrow-up.png) no-repeat 0 14px;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-widget-sort-order {
  padding: 0px;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .form-item-search-api-views-fulltext {
  position: relative;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .form-item-search-api-views-fulltext input {
  padding-left: 15px;
  padding-right: 230px;
  width: 100%;
  height: 54px;
  border-radius: 5px;
  border: 1px solid #c4c4c4;
  font-size: 20px;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .form-item-search-api-views-fulltext .description .dropdown {
  position: absolute;
  top: 8px;
  right: 75px;
  background-color: #fff;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .form-item-search-api-views-fulltext .description .dropdown .current-dd-page {
  border-left: 1px dotted #c4c4c4;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .form-item-search-api-views-fulltext .description label.search-submit {
  cursor: pointer;
  position: absolute;
  right: 0px;
  top: 0px;
  height: 54px;
  width: 71px;
  border-radius: 0px 5px 5px 0px;
  background-color: #007ee5;
  background-image: url(../img/search-icon.png);
  background-position: center center;
  background-repeat: no-repeat;
  display: block;
  text-indent: -99999px;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .form-item-search-api-views-fulltext .description label.search-submit:hover {
  background-color: #b2af39;
}

.search-api-page .panels-flexible-column-last .search-api-fulltext .views-submit-button {
  display: none;
}

.search-api-page .panels-flexible-column-last .pane-current-search-dataset-search-results,
.search-api-page .panels-flexible-column-last .pane-current-search-task-search-results {
  margin: 10px;
}

.search-api-page .panels-flexible-column-last .pane-current-search-dataset-search-results .current-search-item-results,
.search-api-page .panels-flexible-column-last .pane-current-search-task-search-results .current-search-item-results {
  margin: 0px;
}

.search-api-page .panels-flexible-column-last .pane-current-search-dataset-search-results .current-search-item-results h2,
.search-api-page .panels-flexible-column-last .pane-current-search-task-search-results .current-search-item-results h2 {
  color: #7a3b22;
  margin: 0px 0px 15px 0px;
}

.search-api-page .panels-flexible-column-last .pane-current-search-dataset-search-results .current-search-item-results h2 .searched-text,
.search-api-page .panels-flexible-column-last .pane-current-search-task-search-results .current-search-item-results h2 .searched-text {
  font-family: "source-sans", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-weight: normal;
}

.search-api-page .panels-flexible-column-last .pane-current-search-dataset-search-results .current-search-item-active-items ul,
.search-api-page .panels-flexible-column-last .pane-current-search-task-search-results .current-search-item-active-items ul {
  padding: 0px;
  margin: 0px 0px 30px 0px;
}

.search-api-page .panels-flexible-column-last .pane-current-search-dataset-search-results .current-search-item-active-items ul li,
.search-api-page .panels-flexible-column-last .pane-current-search-task-search-results .current-search-item-active-items ul li {
  display: inline-block;
  background-color: #007ee5;
  border-radius: 5px;
  padding-left: 10px;
  padding-right: 3px;
  margin: 2px 0px;
  font-size: 16px;
  color: #fff;
}

.search-api-page .panels-flexible-column-last .pane-current-search-dataset-search-results .current-search-item-active-items ul li a,
.search-api-page .panels-flexible-column-last .pane-current-search-task-search-results .current-search-item-active-items ul li a {
  position: relative;
  top: 2px;
  text-indent: -9999px;
  width: 20px;
  height: 20px;
  float: right;
  background-image: url(../img/icon-cross.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.search-api-page .panels-flexible-column-last .pane-current-search-dataset-search-results .current-search-item-active-items ul li:hover,
.search-api-page .panels-flexible-column-last .pane-current-search-task-search-results .current-search-item-active-items ul li:hover {
  background-color: #b2af39;
}

.search-api-page .panels-flexible-column-last .panels-flexible-column-search_layout-main-inside {
  max-width: 700px;
  margin: 0 auto;
}

.search-api-page .fsdf-facetapi-collapsible {
  padding: 5px 15px 5px 5px;
  margin-bottom: 10px;
}

.search-api-page .fsdf-facetapi-collapsible.active h2.pane-title {
  background-image: url(../img/icon-arrow-down.png);
}

.search-api-page .fsdf-facetapi-collapsible h2.pane-title {
  cursor: pointer;
  font-size: 16px;
  color: #007ee5;
  background-image: url(../img/icon-arrow-right.png);
  background-position: 95% center;
  background-repeat: no-repeat;
  margin: 0px;
  line-height: 20px;
  font-family: "source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-weight: bold;
  letter-spacing: normal;
}

.search-api-page .fsdf-facetapi-collapsible h2.pane-title .items-count {
  color: #fff;
  background-color: #007ee5;
  border-radius: 25px;
  padding: 0px 5px;
  display: inline-block;
  color: white;
  font-weight: 300;
  font-size: 12px;
  line-height: 1em;
  width: 22px;
  text-align: center;
  position: relative;
  top: -2px;
  font-weight: 400;
}

.search-api-page .fsdf-facetapi-collapsible .facetapi-limit-link {
  margin-top: 15px;
  font-size: 16px;
  display: block;
}

.search-api-page .fsdf-facetapi-pane p {
  margin: 0px;
  font-size: 16px;
}

.search-api-page .fsdf-facetapi-pane ul {
  margin: 10px 0px 0px 0px;
  padding: 0px;
}

.search-api-page .fsdf-facetapi-pane ul li {
  display: block;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 8px;
}

.search-api-page .fsdf-facetapi-pane ul li.facet-active {
  background-color: #007ee5;
  border-radius: 5px;
  padding: 3px 3px 3px 10px;
  margin: 2px 0px;
  color: #fff;
}

.search-api-page .fsdf-facetapi-pane ul li.facet-active a.facetapi-active {
  position: relative;
  display: block;
  text-indent: -9999px;
  width: 20px;
  height: 20px;
  float: right;
  background-image: url(../img/icon-cross.png);
  background-position: center center;
  background-repeat: no-repeat;
}

.search-api-page .fsdf-facetapi-pane ul li.facet-active:hover {
  background-color: #b2af39;
}

.search-api-page .facet-api-search-items {
  margin-top: 20px;
}

.search-api-page .facet-api-search-items .views-row {
  max-width: 696px;
  height: 200px;
  margin: 10px 10px 20px 10px;
  display: block;
  background: white;
  border: #007ee5 solid 2px;
  padding: 20px;
  text-align: left;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  -webkit-transition: all ease-in-out .1s;
  transition: all ease-in-out .1s;
  position: relative;
  top: 0;
  overflow: hidden;
}

.search-api-page .facet-api-search-items .views-row .views-field-title {
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 12px;
}

.search-api-page .facet-api-search-items .views-row .views-field-title a:hover {
  text-decoration: none;
}

.search-api-page .facet-api-search-items .views-row .views-field-title a:visited {
  color: #007ee5;
}

.search-api-page .facet-api-search-items .views-row .views-field-field-agency-juristiction {
  margin-right: 30px;
  max-width: 150px;
}

.search-api-page .facet-api-search-items .views-row .views-field-field-theme-working-group {
  max-width: 470px;
}

.search-api-page .facet-api-search-items .views-row .views-field-field-agency {
  max-width: 600px;
}

.search-api-page .facet-api-search-items .views-row .views-field-field-percentage-complete {
  margin-top: 5px;
}

.search-api-page .facet-api-search-items .views-row .views-field-field-agency-juristiction,
.search-api-page .facet-api-search-items .views-row .views-field-field-theme-working-group,
.search-api-page .facet-api-search-items .views-row .views-field-field-agency {
  float: left;
}

.search-api-page .facet-api-search-items .views-row .views-field-field-agency-juristiction span,
.search-api-page .facet-api-search-items .views-row .views-field-field-theme-working-group span,
.search-api-page .facet-api-search-items .views-row .views-field-field-agency span {
  display: block;
}

.search-api-page .facet-api-search-items .views-row .views-field-field-agency-juristiction span.views-label,
.search-api-page .facet-api-search-items .views-row .views-field-field-theme-working-group span.views-label,
.search-api-page .facet-api-search-items .views-row .views-field-field-agency span.views-label {
  text-transform: uppercase;
  color: #7a3b22;
  font-weight: bold;
  font-size: 14px;
}

.search-api-page .facet-api-search-items .views-row .views-field-body {
  clear: both;
  padding-top: 10px;
}

.search-api-page .facet-api-search-items .views-row .views-field-body p {
  margin: 0px;
}

.search-api-page .facet-api-search-items .views-row:hover {
  box-shadow: 0 23px 30px -25px rgba(0, 0, 0, 0.5);
  border: 2px solid #b2af39;
  top: -3px;
  text-decoration: none;
}

.search-api-page .facet-api-search-items .views-row:hover .views-field-title a {
  color: #b2af39;
}

.search-api-page #search-api-task .panels-flexible-column-last .facet-api-search-items .views-row {
  height: 250px;
}

.search-api-page ul.pager {
  text-align: right;
}

.search-api-page ul.pager .pager-first,
.search-api-page ul.pager .pager-last,
.search-api-page ul.pager .pager-next,
.search-api-page ul.pager .pager-previous {
  text-transform: capitalize;
}

.box {
  margin-bottom: 1.44444rem;
  border: 5px solid #cccccc;
  padding: 1em;
}

.box__title {
  margin: 0;
}

.box:focus, .box:hover, .box.is-focus, .box--is-focus {
  border-color: #412d36;
}

.box--highlight {
  border-color: #007ee5;
}

.clearfix:before,
.header .layout-center:before,
.tabs:before {
  content: '';
  display: table;
}

.clearfix:after,
.header .layout-center:after,
.tabs:after {
  content: '';
  display: table;
  clear: both;
}

.comment__section,
.comments {
  margin: 1.44444rem 0;
}

.comment__title {
  margin: 0;
}

.comment__permalink {
  text-transform: uppercase;
  font-size: 75%;
}

.comment--preview,
.comment-preview {
  background-color: #fffadb;
}

.comment--nested,
.indented {
  margin-left: 36px;
}

[dir="rtl"] .comment--nested,
[dir="rtl"] .indented {
  margin-left: 0;
  margin-right: 36px;
}

.pane-agency-contact-details .field-label-inline {
  margin: 5px 0;
}

.pane-agency-contact-details .field-label-inline:before {
  content: '';
  display: table;
}

.pane-agency-contact-details .field-label-inline:after {
  content: '';
  display: table;
  clear: both;
}

.pane-agency-contact-details .field-label-inline .field-label {
  margin-right: -100%;
  margin-top: 0;
  float: left;
  display: block;
  line-height: 18px;
}

.pane-agency-contact-details .field-label-inline .field-items {
  margin-left: 25px;
  float: left;
  display: block;
  font-size: 16px;
  line-height: 18px;
}

.header {
  position: relative;
  z-index: 5;
  background: url("../img/gradient-header.png");
  background-repeat: repeat-x;
  min-height: 243px;
}

.header .header-inner {
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.header .layout-center {
  position: relative;
}

.header__logo {
  float: left;
  margin: 0 10px 0 0;
  padding: 25px 0;
}

[dir="rtl"] .header__logo {
  float: right;
  margin: 0 0 0 10px;
}

.header__logo-image {
  vertical-align: bottom;
}

.header__name-and-slogan {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
  float: left;
}

.header__site-name {
  margin: 0;
}

.header__site-link:link, .header__site-link:visited {
  color: #412d36;
  text-decoration: none;
}

.header__site-link:hover, .header__site-link:focus {
  text-decoration: underline;
}

.header__site-slogan {
  margin: 0;
}

.header__secondary-menu {
  float: right;
}

[dir="rtl"] .header__secondary-menu {
  float: left;
}

.header__region {
  float: right;
}

.header {
  width: 100%;
}

.header .region-header .menu {
  padding: 45px 0 0;
}

.header .region-header .menu li a {
  color: white;
  font-size: 20px;
  text-decoration: none;
  padding: 40px 10px;
  cursor: pointer;
}

.header .region-header .menu li a.active {
  border-bottom: 3px solid white;
}

.header .region-header .menu li a:hover, .header .region-header .menu li a:focus {
  background: rgba(255, 255, 255, 0.1);
  border-bottom: 3px solid rgba(255, 255, 255, 0.5);
}

.header #block-system-user-menu {
  position: absolute;
  top: 115px;
  right: 12px;
}

.header #block-system-user-menu .menu {
  padding: 0;
}

.header #block-system-user-menu .menu a {
  font-size: 16px;
  color: #412d36;
  padding: 5px 10px;
  background: rgba(255, 255, 255, 0.9);
  border: 0;
  border-radius: 0 0 3px 3px;
}

.header #block-system-user-menu .menu a:hover,
.header #block-system-user-menu .menu a:focus,
.header #block-system-user-menu .menu a:active {
  background: rgba(230, 230, 230, 0.9);
}

.region-banner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  min-width: 1300px;
  opacity: .8;
}

.region-banner p {
  margin-top: 0;
}

.region-banner img {
  position: relative;
  width: 1500px;
  height: 700px;
  margin-left: -750px;
  left: 50%;
  max-width: none;
}

@media (min-width: 1500px) {
  .region-banner img {
    width: 100%;
    height: auto;
    display: block;
    position: static;
    margin-left: 0;
  }
}

.region-banner:after {
  content: ' ';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100%;
  background: url("../img/gradient-banner.png");
  background-repeat: repeat-x;
  min-height: 100%;
  background-size: 10px 591px;
  background-position: bottom;
}

@media (min-width: 1500px) {
  .section-tranche-report .region-banner:after,
  .section-tranche .region-banner:after,
  .section-program-activity .region-banner:after {
    background: radial-gradient(80% 50%, rgba(255, 255, 255, 0) 35%, rgba(245, 245, 245, 0.8) 70%, whitesmoke);
    height: 200%;
  }
}

.hidden,
html.js .js-hidden,
html.js .element-hidden,
html.js .js-hide {
  display: none;
}

.highlight-mark,
.new,
.update {
  color: #d10000;
  background-color: transparent;
}

.inline-links,
.inline.links {
  padding: 0;
}

.inline-links__item,
.inline.links li {
  display: inline;
  list-style-type: none;
  padding: 0 1em 0 0;
}

[dir="rtl"] .inline-links__item,
[dir="rtl"] .inline.links li,
.inline.links [dir="rtl"] li {
  display: inline-block;
  padding: 0 0 0 1em;
}

.inline-sibling,
.field-label-inline .field-label,
span.field-label {
  display: inline;
  margin-right: 10px;
}

[dir="rtl"] .inline-sibling,
[dir="rtl"] .field-label-inline .field-label,
.field-label-inline [dir="rtl"] .field-label,
[dir="rtl"] span.field-label {
  margin-right: 0;
  margin-left: 10px;
}

.inline-sibling__child,
.inline-sibling *, .field-label-inline .field-label *, span.field-label * {
  display: inline;
}

.inline-sibling__adjacent,
.inline-sibling + *,
.inline-sibling + * > :first-child,
.inline-sibling + * > :first-child > :first-child, .field-label-inline .field-label + *, span.field-label + *, .field-label-inline .field-label + * > :first-child, span.field-label + * > :first-child, .field-label-inline .field-label + * > :first-child > :first-child, span.field-label + * > :first-child > :first-child {
  display: inline;
}

.field-label-inline .field-label,
.field-label-inline .field-items {
  float: none;
}

.messages,
.messages--status {
  margin: 1.44444rem 0;
  position: relative;
  padding: 10px 10px 10px 44px;
  border: 1px solid #007ee5;
}

[dir="rtl"] .messages,
[dir="rtl"] .messages--status {
  padding: 10px 44px 10px 10px;
  background-position: 99% 8px;
}

.messages__icon {
  position: absolute;
  top: 50%;
  left: 10px;
  height: 24px;
  width: 24px;
  margin-top: -12px;
  line-height: 1;
}

[dir="rtl"] .messages__icon {
  left: auto;
  right: 0;
}

.messages__icon path {
  fill: #007ee5;
}

.messages__highlight,
.messages--error .error,
.messages.error .error {
  color: #412d36;
}

.messages__list {
  margin: 0;
}

.messages__item {
  list-style-image: none;
}

.messages--ok-color,
.messages,
.messages--status,
.ok,
.revision-current {
  background-color: white;
  color: #007ee5;
}

.messages--warning-color,
.messages--warning,
.messages.warning,
.warning {
  background-color: #fffce6;
  color: #412d36;
}

.messages--error-color,
.messages--error,
.messages.error,
.error {
  background-color: #fff5f5;
  color: #d10000;
}

.messages--warning,
.messages.warning {
  border-color: #fd0;
}

.messages--warning path, .messages.warning path {
  fill: #fd0;
}

.messages--error,
.messages.error {
  border-color: #d10000;
}

.messages--error path, .messages.error path {
  fill: #d10000;
}

@media print {
  .print-none,
  .toolbar,
  .action-links,
  .links,
  .book-navigation,
  .forum-topic-navigation,
  .feed-icons {
    display: none;
  }
}

.status-label, .status-green .field-name-field-status .field-item.even, .status-amber .field-name-field-status .field-item.even, .status-red .field-name-field-status .field-item.even, .status-bar-wrapper.status-green .status, .status-bar-wrapper.status-amber .status, .status-bar-wrapper.status-red .status, .status-bar-wrapper.status-not-started .status, .status-bar-wrapper.status-completed .status {
  text-transform: uppercase;
  font-size: 12px;
  text-align: center;
  color: white;
  letter-spacing: 1px;
  border-radius: 2px;
  min-width: 50px;
  padding: 0;
  display: inline-block;
}

.status-green .field-name-field-status .field-item.even {
  background-color: #009d00;
}

.status-amber .field-name-field-status .field-item.even {
  background-color: #e76d00;
}

.status-red .field-name-field-status .field-item.even {
  background-color: #d10000;
}

.status-bar-wrapper {
  height: 30px;
}

.status-bar-wrapper .status {
  float: left;
  padding: 0 10px;
  border-radius: 3px;
  color: #fff;
  line-height: 1.7;
  text-transform: uppercase;
}

.status-bar-wrapper .progressbar-formatter {
  float: left;
  width: 300px;
}

.status-bar-wrapper .progressbar {
  height: 2px;
  width: 250px;
  margin: 10px 0;
  overflow: visible;
  border-left: medium none;
  border-right: medium none;
}

.status-bar-wrapper .ui-progressbar-value {
  border: medium none;
  border-radius: 0;
  height: 10px;
  margin: -5px 0 0;
}

.status-bar-wrapper .progressbar-label {
  position: relative;
  text-shadow: none;
  left: auto;
  top: auto;
  float: right;
  width: 40px;
  margin-top: -2px;
}

.status-bar-wrapper.status-green .status {
  background-color: #009d00;
}

.status-bar-wrapper.status-green .ui-progressbar-value {
  background: #009d00;
}

.status-bar-wrapper.status-amber .status {
  background-color: #e76d00;
}

.status-bar-wrapper.status-amber .ui-progressbar-value {
  background: #e76d00;
}

.status-bar-wrapper.status-red .status {
  background-color: #d10000;
}

.status-bar-wrapper.status-red .ui-progressbar-value {
  background: #d10000;
}

.status-bar-wrapper.status-not-started .status {
  background-color: #999999;
  padding: 0 8px;
}

.status-bar-wrapper.status-not-started .ui-progressbar-value {
  background: #999999;
}

.status-bar-wrapper.status-completed .status {
  background-color: #224272;
  padding: 0 8px;
}

.status-bar-wrapper.status-completed .ui-progressbar-value {
  background: #224272;
}

.responsive-video,
.media-youtube-video,
.media-vimeo-preview-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 25px;
  height: 0;
}

.responsive-video__embed,
.responsive-video iframe,
.media-youtube-video iframe,
.media-vimeo-preview-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.responsive-video--4-3 {
  padding-bottom: 75%;
}

.visually-hidden,
.element-invisible,
.element-focusable,
.breadcrumb__title,
.main-navigation .block-menu .block__title,
.main-navigation .block-menu-block .block__title,
.header .block-menu .block__title,
.header .block-menu-block .block__title {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.visually-hidden--off,
.visually-hidden--focusable:active,
.visually-hidden--focusable:focus,
.element-focusable:active,
.element-focusable:focus {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: visible;
}

.watermark {
  font-family: "korolev-compressed", Impact, "Arial Narrow", Helvetica, sans-serif;
  text-transform: uppercase;
  display: block;
  height: 0;
  overflow: visible;
  background-color: transparent;
  color: #eeeeee;
  font-size: 75px;
  line-height: 1;
  text-align: center;
  text-shadow: 0 0 1px rgba(0, 0, 0, 0.1);
  word-wrap: break-word;
}

.section-fsdf-theme .col-middle-top .panels-flexible-region-first,
.section-agency .col-middle-top .panels-flexible-region-first {
  width: 137px;
  margin-right: 30px;
  margin-left: 50px;
}

.section-fsdf-theme .col-middle-top .panels-flexible-region-first .panel-pane:first-of-type .field .field-label,
.section-agency .col-middle-top .panels-flexible-region-first .panel-pane:first-of-type .field .field-label {
  margin-top: 0;
}

.section-fsdf-theme .col-middle-top .panels-flexible-region-first h2,
.section-agency .col-middle-top .panels-flexible-region-first h2 {
  color: #7a3b22;
}

.section-fsdf-theme .col-middle-top .panels-flexible-region-first .field-name-field-link-to-website a,
.section-agency .col-middle-top .panels-flexible-region-first .field-name-field-link-to-website a {
  word-break: break-all;
  display: block;
}

.section-fsdf-theme .field-name-field-theme-icon {
  float: left;
  background: white;
  border-radius: 50%;
  border: 2px solid;
  width: 60px;
  height: 60px;
  padding: 10px;
}

.section-fsdf-theme .field-name-field-theme-icon img {
  display: block;
  height: auto;
}

.col-middle-top .panels-flexible-region-first {
  width: 117px;
  margin-right: 30px;
  margin-left: 70px;
}

.col-middle-top .panels-flexible-region-first .pane-entity-field {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 30px;
}

.col-middle-top .panels-flexible-region-first .pane-entity-field .field-label {
  color: #7a3b22;
}

.col-middle-top .panels-flexible-region-first .pane-entity-field:last-of-type {
  border-bottom: 0;
}

.col-middle-top .panels-flexible-region-last {
  font-size: 24px;
  line-height: 36px;
  width: 450px;
}

.section-agency .col-middle-top .panels-flexible-region-first {
  width: 200px;
  margin-left: 0;
}

.section-agency .col-middle-top .field-name-field-theme-icon {
  padding: 40px 0 0;
}

.pane-node-field-datasets-in-theme,
.pane-node-field-national-datasets {
  width: 70%;
  margin: auto;
}

.pane-node-field-datasets-in-theme .field-item,
.pane-node-field-national-datasets .field-item {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(191, 191, 191, 0.5);
}

.pane-node-field-datasets-in-theme .field-item:last-of-type,
.pane-node-field-national-datasets .field-item:last-of-type {
  border-bottom: 0;
}

.view-fsdf-dataset {
  width: 70%;
  margin: auto;
}

.view-fsdf-dataset .item-list ul {
  padding: 0;
}

.view-fsdf-dataset .item-list ul li {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid rgba(191, 191, 191, 0.5);
  list-style: none;
}

.view-fsdf-dataset .item-list ul li:last-of-type {
  border-bottom: 0;
}

.field-name-field-work-package .field-item {
  font-size: 16px;
  line-height: 18px;
  padding-bottom: 10px;
}

.field-items p {
  margin: 0 0 10px;
}

.page-home .pane-fsdf-theme ul {
  margin: 0 -10px;
  width: 100%;
  padding: 0;
}

.page-home .pane-fsdf-theme ul:before {
  content: '';
  display: table;
}

.page-home .pane-fsdf-theme ul:after {
  content: '';
  display: table;
  clear: both;
}

.page-home .pane-fsdf-theme ul li {
  list-style: none;
  float: left;
  width: 20%;
}

.page-home .pane-fsdf-theme ul .view-homepage-theme-link {
  margin: 10px;
  display: block;
  font-size: 36px;
  background: white;
  border: #007ee5 solid 2px;
  padding: 20px;
  text-align: center;
  border-radius: 2px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  transition: all ease-in-out .1s;
  position: relative;
  top: 0;
}

.page-home .pane-fsdf-theme ul .view-homepage-theme-link:visited {
  color: #397eb4;
}

.page-home .pane-fsdf-theme ul .view-homepage-theme-link:hover, .page-home .pane-fsdf-theme ul .view-homepage-theme-link:focus {
  box-shadow: 0 23px 30px -25px rgba(0, 0, 0, 0.5);
  border: 2px solid #b2af39;
  top: -3px;
  text-decoration: none;
  color: #b2af39;
}

.panels-flexible-region-3-scrollspy {
  margin-top: -90px;
}

.panels-flexible-region-3-scrollspy h2 {
  color: #412d36;
  background-color: #f5f5f5;
  display: table;
  padding: 0 17px;
  font-family: "source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
}

.panels-flexible-region-3-scrollspy ul {
  list-style: none;
  margin: 10px 0 0;
}

.panels-flexible-region-3-scrollspy ul li {
  margin-bottom: 10px;
}

.panels-flexible-region-3-scrollspy ul li.active a {
  font-weight: bold;
  color: black;
  padding-left: 20px;
  border-left: 1px solid;
  margin-left: -20px;
}

.section-dataset .view-datasets {
  width: 720px;
}

.section-dataset .view-datasets .view-content .item-list {
  text-align: center;
  margin-bottom: 50px;
  padding-bottom: 50px;
  border-bottom: 1px solid #bfbfbf;
}

.section-dataset .view-datasets .view-content .item-list h3 {
  margin-top: 0;
}

.section-dataset .view-datasets .view-content .item-list ul {
  width: 70%;
  margin: auto;
  padding: 0;
}

.section-dataset .view-datasets .view-content .item-list ul li {
  padding-top: 15px;
  margin-top: 15px;
  border-top: 1px solid rgba(191, 191, 191, 0.5);
  text-align: left;
}

.section-dataset .view-datasets .view-content .item-list ul li:first-of-type {
  border-top: none;
  margin-top: inherit;
  padding-top: inherit;
}

.section-dataset .view-datasets .view-content .item-list ul li .collapsible {
  border: none;
}

.section-dataset .view-datasets .view-content .item-list ul li .collapsible .fieldset-legend a {
  min-width: 457px;
  font-size: 18px;
  padding-left: 0;
}

.section-dataset .view-datasets .view-content .item-list ul li .collapsible .fieldset-legend span {
  display: none;
}

.section-dataset .view-datasets .view-content .item-list ul li .collapsible .fieldset-wrapper .field-type-text-with-summary .field-label {
  margin-top: 0;
}

.node-type-program-activity .layout-3col {
  margin-top: -100px;
}

.view-header-tasks {
  text-align: center;
}

.view-header-tasks a {
  border: 1px solid;
  padding: 15px 30px;
  box-shadow: inset 0 2px 0 #ffffff;
  background-color: #f5f7f8;
  border-radius: 2px;
}

.view-header-tasks a span {
  font-weight: bold;
  font-size: larger;
  padding: 4px;
}

.view-header-tasks a:hover, .view-header-tasks a:focus {
  box-shadow: inset 0 1px 0px #83bdec;
  background-color: #007ee5;
  color: white;
  border-color: #07c;
  text-decoration: none;
}

.node-type-program-activity h1,
.page-dataset-tasks h1 {
  font-family: "source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  letter-spacing: 0;
  text-transform: none;
  text-align: left;
  line-height: 47px;
  font-size: 40px;
  font-weight: normal;
}

.node-type-program-activity .node-program-activity,
.node-type-program-activity .view-datasets,
.page-dataset-tasks .node-program-activity,
.page-dataset-tasks .view-datasets {
  background: url("../img/title-bg.jpg") 0 bottom repeat-x;
  padding-bottom: 25px;
}

.node-type-program-activity .node-program-activity .group-left,
.node-type-program-activity .view-datasets .group-left,
.page-dataset-tasks .node-program-activity .group-left,
.page-dataset-tasks .view-datasets .group-left {
  width: 150px;
  margin-right: 30px;
}

.node-type-program-activity .node-program-activity .group-left .field,
.node-type-program-activity .view-datasets .group-left .field,
.page-dataset-tasks .node-program-activity .group-left .field,
.page-dataset-tasks .view-datasets .group-left .field {
  border-bottom: 1px solid #bfbfbf;
  padding-bottom: 10px;
}

.node-type-program-activity .node-program-activity .group-left .field.field-name-field-work-package .field-label,
.node-type-program-activity .view-datasets .group-left .field.field-name-field-work-package .field-label,
.page-dataset-tasks .node-program-activity .group-left .field.field-name-field-work-package .field-label,
.page-dataset-tasks .view-datasets .group-left .field.field-name-field-work-package .field-label {
  margin-top: 0;
}

.node-type-program-activity .node-program-activity .group-left .field.field-name-field-theme-working-group, .node-type-program-activity .node-program-activity .group-left .field.field-name-field-delivery-timeframe,
.node-type-program-activity .view-datasets .group-left .field.field-name-field-theme-working-group,
.node-type-program-activity .view-datasets .group-left .field.field-name-field-delivery-timeframe,
.page-dataset-tasks .node-program-activity .group-left .field.field-name-field-theme-working-group,
.page-dataset-tasks .node-program-activity .group-left .field.field-name-field-delivery-timeframe,
.page-dataset-tasks .view-datasets .group-left .field.field-name-field-theme-working-group,
.page-dataset-tasks .view-datasets .group-left .field.field-name-field-delivery-timeframe {
  border-bottom: none;
}

.node-type-program-activity .node-program-activity .group-right,
.node-type-program-activity .view-datasets .group-right,
.page-dataset-tasks .node-program-activity .group-right,
.page-dataset-tasks .view-datasets .group-right {
  width: 500px;
}

.node-type-program-activity .view-tasks .view-header span,
.page-dataset-tasks .view-tasks .view-header span {
  font-weight: bold;
}

.node-type-program-activity .view-tasks .view-content .item-list ul,
.page-dataset-tasks .view-tasks .view-content .item-list ul {
  padding: 0;
}

.node-type-program-activity .view-tasks .view-content .item-list ul li,
.page-dataset-tasks .view-tasks .view-content .item-list ul li {
  background-color: white;
  padding: 15px;
  margin-bottom: 15px;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07);
  width: 690px;
}

.node-type-program-activity .view-tasks .view-content .item-list ul li .node-tasks .field-name-title .title-prefix,
.page-dataset-tasks .view-tasks .view-content .item-list ul li .node-tasks .field-name-title .title-prefix {
  margin: 0;
}

.node-type-program-activity .view-tasks .view-content .item-list ul li .node-tasks .field-name-title h2,
.page-dataset-tasks .view-tasks .view-content .item-list ul li .node-tasks .field-name-title h2 {
  margin-top: 0;
  font-size: 24px;
  font-family: "source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  text-transform: none;
  letter-spacing: 0;
}

.page-tranche div.vertical-tabs, .admin-menu div.vertical-tabs {
  margin: auto;
  border: none;
}

.page-tranche div.vertical-tabs .view-header, .admin-menu div.vertical-tabs .view-header {
  margin-left: -100px;
}

.page-tranche div.vertical-tabs ul.vertical-tabs-list, .admin-menu div.vertical-tabs ul.vertical-tabs-list {
  border: none;
  margin: -1px 30px -1px -100px;
  max-width: 240px;
}

.page-tranche div.vertical-tabs ul.vertical-tabs-list li, .admin-menu div.vertical-tabs ul.vertical-tabs-list li {
  background: transparent;
  border: none;
  background: url("../img/title-bg.jpg") 0 bottom repeat-x;
  padding: 15px;
}

.page-tranche div.vertical-tabs ul.vertical-tabs-list li:last-of-type, .admin-menu div.vertical-tabs ul.vertical-tabs-list li:last-of-type {
  background-image: none;
}

.page-tranche div.vertical-tabs ul.vertical-tabs-list li a, .admin-menu div.vertical-tabs ul.vertical-tabs-list li a {
  color: #007ee5;
  margin: 0;
}

.page-tranche div.vertical-tabs ul.vertical-tabs-list li a span, .admin-menu div.vertical-tabs ul.vertical-tabs-list li a span {
  font-size: 16px;
  text-transform: initial;
  line-height: 20px;
  font-weight: normal;
}

.page-tranche div.vertical-tabs ul.vertical-tabs-list .selected, .admin-menu div.vertical-tabs ul.vertical-tabs-list .selected {
  background-color: white;
  position: relative;
  border-radius: 0 0 4px 4px;
  border-bottom: 1px solid #dbdbdb;
  background-image: none;
  border-top: 1px solid #ebebeb;
  margin-top: -2px;
}

.page-tranche div.vertical-tabs ul.vertical-tabs-list .selected:after, .admin-menu div.vertical-tabs ul.vertical-tabs-list .selected:after {
  content: " ";
  display: block;
  position: absolute;
  right: 0;
  top: 30%;
  background: url(../img/triangle-white-right.png) no-repeat;
  z-index: 100000;
  width: 20px;
  margin-right: -20px;
  height: 30px;
}

.page-tranche div.vertical-tabs ul.vertical-tabs-list .selected:after a, .admin-menu div.vertical-tabs ul.vertical-tabs-list .selected:after a {
  transition: none;
}

.page-tranche div.vertical-tabs ul.vertical-tabs-list ul, .admin-menu div.vertical-tabs ul.vertical-tabs-list ul {
  max-width: 100%;
  margin-left: 0;
}

.vertical-tabs.clearfix {
  margin-bottom: 30px !important;
  background: url("../img/title-bg.jpg") 0 bottom repeat-x;
}

.form-actions {
  margin-top: 30px;
  float: right;
}

.form-actions #edit-preview {
  background: transparent;
  border: 1px solid #007ee5;
  color: #007ee5;
  margin-left: 10px;
}

.form-submit {
  border: 2px solid #dbdbdb;
  padding: 5px;
  min-width: 80px;
  color: #fff;
  background-color: #007ee5;
  background: -webkit-linear-gradient(#007ee5, #0071ce);
  background: linear-gradient(#007ee5, #0071ce);
  border-radius: 4px;
}

.view-content ul {
  list-style: none;
}

@media print {
  .breadcrumb {
    display: none;
  }
}

.breadcrumb__list {
  margin: 0;
  padding: 0;
}

.breadcrumb__item {
  display: inline;
  list-style-type: none;
  margin: 0;
  padding: 0;
}

.more-link,
.more-help-link {
  text-align: right;
}

[dir="rtl"] .more-link,
[dir="rtl"] .more-help-link {
  text-align: left;
}

.more-link__help-icon,
.more-help-link a {
  padding: 1px 0 1px 20px;
  background-image: url(../sass/navigation/more-link/more-link__help-icon.svg);
  background-position: 0 50%;
  background-repeat: no-repeat;
}

[dir="rtl"] .more-link__help-icon,
[dir="rtl"] .more-help-link a,
.more-help-link [dir="rtl"] a {
  padding: 1px 20px 1px 0;
  background-position: 100% 50%;
}

.nav-menu__item,
.menu__item {
  list-style-image: url(../sass/navigation/nav-menu/leaf.svg);
  list-style-type: square;
}

.nav-menu__item.is-expanded, .nav-menu__item--is-expanded,
.is-expanded.menu__item,
.menu__item.is-expanded {
  list-style-image: url(../sass/navigation/nav-menu/expanded.svg);
  list-style-type: circle;
}

.nav-menu__item.is-collapsed, .nav-menu__item--is-collapsed,
.is-collapsed.menu__item,
.menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed.svg);
  list-style-type: disc;
}

[dir="rtl"] .nav-menu__item.is-collapsed, [dir="rtl"] .nav-menu__item--is-collapsed,
[dir="rtl"] .is-collapsed.menu__item,
[dir="rtl"] .menu__item.is-collapsed {
  list-style-image: url(../sass/navigation/nav-menu/collapsed-rtl.svg);
}

.nav-menu__link.is-active, .nav-menu__link--is-active,
.menu a.active {
  color: #412d36;
}

.view-grouping {
  padding: 0;
  border: 0;
  margin: 0 0 20px;
}

.view-grouping .view-grouping-header {
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 10px;
  font-weight: 600;
  color: #376e97;
}

.view-grouping .group-count {
  background-color: #007ee5;
  border-radius: 25px;
  padding: 0 5px;
  display: inline-block;
  color: white;
  font-weight: 300;
  font-size: 12px;
  line-height: 1em;
  position: relative;
  top: -2px;
}

.view-grouping .item-list .view-grouping {
  margin-bottom: 0;
}

.view-grouping .item-list .view-grouping .view-grouping-header {
  font-size: 15px;
  padding-left: 10px;
  letter-spacing: 0;
  text-transform: inherit;
}

.view-grouping .view-grouping-content ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

.view-grouping .view-grouping-content ul li {
  font-size: 16px;
  line-height: 18px;
  padding: 0 0 10px 10px;
}

.view-grouping .view-grouping-content ul li:last-of-type {
  margin-bottom: 20px;
}

.col-right .field-items .even,
.col-right .field-items .odd {
  padding-bottom: 10px;
  font-size: 16px;
  line-height: 18px;
}

.navbar,
.main-navigation .links,
.main-navigation .menu,
.header .links,
.header .menu {
  margin: 0;
  padding: 0;
  text-align: left;
}

[dir="rtl"] .navbar,
[dir="rtl"] .main-navigation .links,
.main-navigation [dir="rtl"] .links,
[dir="rtl"]
.main-navigation .menu,
.main-navigation [dir="rtl"] .menu,
[dir="rtl"]
.header .links,
.header [dir="rtl"] .links,
[dir="rtl"]
.header .menu,
.header [dir="rtl"] .menu {
  text-align: right;
}

.navbar__item,
.navbar li, .main-navigation .links li,
.main-navigation .menu li,
.header .links li,
.header .menu li {
  float: left;
  padding: 0 10px 0 0;
  list-style-type: none;
  list-style-image: none;
}

[dir="rtl"] .navbar__item, [dir="rtl"]
.navbar li, [dir="rtl"] .main-navigation .links li, .main-navigation [dir="rtl"] .links li, [dir="rtl"]
.main-navigation .menu li,
.main-navigation [dir="rtl"] .menu li, [dir="rtl"]
.header .links li,
.header [dir="rtl"] .links li, [dir="rtl"]
.header .menu li,
.header [dir="rtl"] .menu li {
  float: right;
  padding: 0 0 0 10px;
}

/* Style The Dropdown Button */
.current-dd-page {
  /*background-color: none;*/
  color: #007ee5;
  padding: 5px;
  padding-left: 20px;
  font-size: 20px;
  border: none;
  cursor: pointer;
  background-image: url(../img/icon-arrow-down.png);
  background-position: 95% center;
  background-repeat: no-repeat;
  display: block;
  width: 150px;
}

.current-dd-page:hover {
  text-decoration: none;
}

/* The container <div> - needed to position the dropdown content */
/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  font-size: 20px;
  padding-left: 20px;
  display: none;
  position: absolute;
  background-color: #fff;
  width: 150px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
}

.dropdown-content:hover {
  background-color: #b2af39;
}

/* Links inside the dropdown */
.dropdown-content a {
  padding: 5px 5px 5px 0px;
  color: #007ee5;
  display: block;
}

.dropdown-content a:hover {
  text-decoration: none;
  color: #fff;
}

/* Change color of dropdown links on hover */
/*.dropdown-content a:hover {background-color: #f1f1f1}*/
/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
  display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/*.dropdown:hover{
    background-color: #3e8e41;
}*/
.pager {
  clear: both;
  padding: 0;
  text-align: center;
}

@media print {
  .pager {
    display: none;
  }
}

.pager__item,
.pager__current-item,
.pager-current,
.pager-item,
.pager-first,
.pager-previous,
.pager-next,
.pager-last,
.pager-ellipsis {
  display: inline;
  padding: 0 .5em;
  list-style-type: none;
  background-image: none;
}

.pager__current-item,
.pager-current {
  font-weight: bold;
}

.skip-link {
  display: block;
  padding: 2px 0 3px;
  text-align: center;
}

.skip-link:link, .skip-link:visited {
  background-color: #666666;
  color: #fff;
}

.skip-link__wrapper {
  margin: 0;
}

@media print {
  .skip-link__wrapper {
    display: none;
  }
}

.tabs {
  margin: 1.08333rem 0 0;
  line-height: 1.80556rem;
  border-bottom: 1px solid #cccccc \0/ie;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
  padding: 0 2px;
  list-style: none;
  white-space: nowrap;
}

@media print {
  .tabs {
    display: none;
  }
}

.tabs__tab {
  float: left;
  margin: 0 3px;
  border: 1px solid #cccccc;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-color: transparent;
  border-bottom: 0 \0/ie;
  overflow: hidden;
  background: #dddddd;
}

[dir="rtl"] .tabs__tab {
  float: right;
}

.tabs__tab.is-active {
  border-bottom-color: #f5f5f5;
}

.tabs__tab-link {
  padding: 0 1.44444rem;
  display: block;
  text-decoration: none;
  transition: color .3s, background .3s, border .3s;
  text-shadow: #fff 0 1px 0;
  color: #412d36;
  background: #dddddd;
  letter-spacing: 1px;
}

.tabs__tab-link:focus, .tabs__tab-link:hover {
  background: #eaeaea;
}

.tabs__tab-link:active, .tabs__tab-link.is-active, .tabs__tab-link--is-active {
  background: #f5f5f5;
  text-shadow: none;
}

.tabs--off,
.views-displays .secondary {
  margin: 0;
  border-bottom: 0;
  padding: 0;
  background-image: none;
}

.tabs--secondary {
  margin-top: 1.44444rem;
  font-size: 1rem;
  background-image: none;
}

.tabs + .tabs--secondary {
  margin-top: 0;
  background-image: linear-gradient(to top, #cccccc 1px, transparent 1px);
}

.tabs--secondary .tabs__tab {
  margin: 0.54167rem 0.16667rem;
  border: 0;
  background: transparent;
}

[dir="rtl"] .tabs--secondary .tabs__tab {
  float: right;
}

.tabs--secondary .tabs__tab.is-active {
  border-bottom-color: transparent;
}

.tabs--secondary .tabs__tab-link {
  border: 1px solid #cccccc;
  border-radius: 26px;
  color: #666666;
  background: #f1f1f1;
  letter-spacing: normal;
}

.tabs--secondary .tabs__tab-link:focus, .tabs--secondary .tabs__tab-link:hover {
  color: #333333;
  background: #dddddd;
  border-color: #999999;
}

.tabs--secondary .tabs__tab-link:active, .tabs--secondary .tabs__tab-link.is-active, .tabs--secondary .tabs__tab-link--is-active {
  color: white;
  text-shadow: #333333 0 1px 0;
  background: #666666;
  border-color: black;
}

#admin-menu {
  background: rgba(0, 0, 0, 0.3);
  box-shadow: none;
  -webkit-box-shadow: none;
  position: absolute !important;
}

#admin-menu #admin-menu-wrapper {
  max-width: 1300px;
  margin: auto;
}

@media (min-width: 1500px) {
  #admin-menu {
    background: black;
  }
}

#admin-menu a {
  font-family: "source-sans-pro", Arial, Helvetica, "Nimbus Sans L", sans-serif;
  font-size: 14px;
}

#admin-menu .admin-menu-account a {
  background: #007ee5 !important;
}

ul.tabs {
  margin-bottom: 20px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 4px;
  border-bottom: 1px solid white;
  box-shadow: inset 0 6px 15px -12px;
  background-image: none;
  display: flex;
}

ul.tabs li {
  border-radius: 2px 0;
  background: none;
}

.autocomplete,
.form-autocomplete {
  background-image: url(../sass/forms/autocomplete/throbber-inactive.png);
  background-position: 100% center;
  background-repeat: no-repeat;
}

[dir="rtl"] .autocomplete,
[dir="rtl"] .form-autocomplete {
  background-position: 0% center;
}

.autocomplete__list-wrapper,
#autocomplete {
  border: 1px solid;
  overflow: hidden;
  position: absolute;
  z-index: 100;
}

.autocomplete__list,
#autocomplete ul {
  list-style: none;
  list-style-image: none;
  margin: 0;
  padding: 0;
}

.autocomplete__list-item,
#autocomplete li {
  background: #f5f5f5;
  color: #412d36;
  cursor: default;
  white-space: pre;
}

.autocomplete__list-item.is-selected, .autocomplete__list-item--is-selected,
#autocomplete li.is-selected,
#autocomplete .selected {
  background: #007ee5;
  color: #fff;
}

.autocomplete.is-throbbing, .autocomplete--is-throbbing,
.is-throbbing.form-autocomplete,
.form-autocomplete.throbbing {
  background-image: url(../sass/forms/autocomplete/throbber-active.gif);
}

.collapsible-fieldset,
.collapsible {
  position: relative;
}

.collapsible-fieldset__legend a, .collapsible > legend .fieldset-legend a {
  display: block;
  background-image: url("../img/icon-arrow-down.png");
  background-position: 95% center;
  background-repeat: no-repeat;
  padding: 5px 15px 5px 5px;
  font-size: 16px;
}

.collapsible-fieldset__legend a:hover, .collapsible-fieldset__legend a:focus, .collapsible > legend .fieldset-legend a:hover, .collapsible > legend .fieldset-legend a:focus {
  text-decoration: none;
  background-color: #eee;
}

[dir="rtl"] .collapsible-fieldset__legend a, [dir="rtl"] .collapsible > legend .fieldset-legend a, .collapsible > legend [dir="rtl"] .fieldset-legend a {
  padding-left: 0;
  padding-right: 15px;
  background-position: right 4px top 50%;
}

.collapsible-fieldset__legend.fsdf-dataset a, .collapsible > legend .fsdf-dataset.fieldset-legend a {
  background-position: 53% center;
}

.collapsible-fieldset__summary,
.collapsible > legend .fieldset-legend .summary {
  color: #999999;
  font-size: .9em;
  margin-left: .5em;
}

.collapsible-fieldset.is-collapsed, .collapsible-fieldset--is-collapsed,
.is-collapsed.collapsible,
.collapsible.collapsed {
  border-bottom-width: 0;
  border-left-width: 0;
  border-right-width: 0;
  height: 1em;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__wrapper, .collapsible-fieldset--is-collapsed__wrapper, .is-collapsed.collapsible .collapsible-fieldset__wrapper,
.collapsible.collapsed .fieldset-wrapper {
  display: none;
}

.collapsible-fieldset.is-collapsed .collapsible-fieldset__legend a,
.collapsible-fieldset--is-collapsed__legend a, .is-collapsed.collapsible .collapsible-fieldset__legend a, .collapsible.collapsed .fieldset-legend a, .collapsible-fieldset.is-collapsed .collapsible > legend .fieldset-legend a, .collapsible > legend .collapsible-fieldset.is-collapsed .fieldset-legend a, .is-collapsed.collapsible .collapsible > legend .fieldset-legend a, .collapsible > legend .is-collapsed.collapsible .fieldset-legend a {
  background-image: url("../img/icon-arrow-right.png");
}

[dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible-fieldset__legend a, [dir="rtl"]
.collapsible-fieldset--is-collapsed__legend a, [dir="rtl"] .is-collapsed.collapsible .collapsible-fieldset__legend a, [dir="rtl"] .collapsible.collapsed .fieldset-legend a, .collapsible.collapsed [dir="rtl"] .fieldset-legend a, [dir="rtl"] .collapsible-fieldset.is-collapsed .collapsible > legend .fieldset-legend a, .collapsible > legend [dir="rtl"] .collapsible-fieldset.is-collapsed .fieldset-legend a, [dir="rtl"] .is-collapsed.collapsible .collapsible > legend .fieldset-legend a, .collapsible > legend [dir="rtl"] .is-collapsed.collapsible .fieldset-legend a {
  background-image: url(../sass/forms/collapsible-fieldset/collapsed-rtl.svg);
  background-position: right 4px top 50%;
}

.view-input-datasets .summary,
.view-agencies .summary {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
  word-wrap: normal;
}

.view-input-datasets .fieldset-legend,
.view-agencies .fieldset-legend {
  display: block;
}

.view-input-datasets .view-grouping-header,
.view-agencies .view-grouping-header {
  margin: 0;
}

.view-input-datasets .fieldset-wrapper,
.view-agencies .fieldset-wrapper {
  margin-top: 10px;
}

.form-item {
  margin: 1.44444rem 0;
}

.form-item__required,
.form-required {
  color: #d10000;
}

.form-item__description,
.form-item .description {
  font-size: 1rem;
}

.form-item--inline div,
.form-item--inline label, .container-inline .form-item div, .container-inline .form-item label {
  display: inline;
}

.form-item--inline__exception,
.container-inline .fieldset-wrapper {
  display: block;
}

.form-item--tight,
.form-item--radio,
.form-type-radio,
.form-type-checkbox,
.password-parent,
.confirm-parent,
table .form-item {
  margin: 0;
}

.form-item--radio .form-item__label, .form-item--radio__label, .form-type-radio .form-item__label,
.form-type-checkbox .form-item__label,
label.option {
  display: inline;
  font-weight: normal;
}

.form-item--radio .form-item__description, .form-item--radio__description, .form-type-radio .form-item__description,
.form-type-checkbox .form-item__description,
.form-type-radio .description,
.form-type-checkbox .description,
.form-item--radio .form-item .description,
.form-item .form-item--radio .description,
.form-type-radio .form-item .description,
.form-item .form-type-radio .description,
.form-type-checkbox .form-item .description,
.form-item
.form-type-checkbox .description {
  margin-left: 1.4em;
}

.form-item.is-error .form-item__widget, .form-item--is-error__widget,
.form-item.is-error input,
.form-item.is-error textarea,
.form-item.is-error select,
.form-item--is-error input,
.form-item--is-error textarea,
.form-item--is-error select,
.form-item input.error,
.form-item textarea.error,
.form-item select.error {
  border: 1px solid #d10000;
}

.form-table__sticky-header,
.sticky-header {
  position: fixed;
  visibility: hidden;
  margin-top: 0;
  background-color: #f5f5f5;
}

.form-table__sticky-header.is-sticky, .form-table__sticky-header--is-sticky,
.is-sticky.sticky-header {
  visibility: visible;
}

.form-table__header,
.form-table th,
form table th {
  border-bottom: 3px solid #cccccc;
  padding-right: 1em;
  text-align: left;
}

[dir="rtl"] .form-table__header,
[dir="rtl"] .form-table th,
.form-table [dir="rtl"] th,
[dir="rtl"] form table th,
form table [dir="rtl"] th {
  text-align: right;
  padding-left: 1em;
  padding-right: 0;
}

.form-table__body,
.form-table tbody,
form table tbody {
  border-top: 1px solid #cccccc;
}

.form-table__row,
.form-table tbody tr,
form table tbody tr {
  padding: .1em .6em;
  border-bottom: 1px solid #cccccc;
  background-color: #eeeeee;
}

.form-table__row:nth-child(even),
.form-table tbody tr:nth-child(even),
form table tbody tr:nth-child(even) {
  background-color: #f5f5f5;
}

.form-table__row.is-active, .form-table__row--is-active,
.form-table tbody tr.is-active,
form table tbody tr.is-active,
td.active {
  background-color: #dddddd;
}

.form-table__row.is-disabled, .form-table__row--is-disabled,
.form-table tbody tr.is-disabled,
form table tbody tr.is-disabled,
td.menu-disabled {
  background: #cccccc;
}

.form-table__row.is-selected, .form-table__row--is-selected,
.form-table tbody tr.is-selected,
form table tbody tr.is-selected,
tr.selected td {
  background: #fffdf0;
}

.form-table__list,
.form-table ul,
form table ul {
  margin: 0;
}

.form-table__narrow-column,
.form-table th.form-table__narrow-column,
form table th.form-table__narrow-column,
td .checkbox,
th .checkbox {
  width: -moz-min-content;
  width: -webkit-min-content;
  text-align: center;
}

.progress-bar,
.progress {
  font-weight: bold;
}

.progress-bar__bar,
.progress .bar {
  border-radius: 3px;
  margin: 0 .2em;
  border: 1px solid #666666;
  background-color: #cccccc;
}

.progress-bar__fill,
.progress .filled {
  height: 1.5em;
  width: 5px;
  background: #007ee5 url(../sass/forms/progress-bar/progress-bar.gif) repeat 0 0;
}

.progress-bar__percentage,
.progress .percentage {
  float: right;
}

[dir="rtl"] .progress-bar__percentage,
[dir="rtl"] .progress .percentage,
.progress [dir="rtl"] .percentage {
  float: left;
}

.progress-bar--inline,
.ajax-progress-bar {
  width: 16em;
  display: inline-block;
}

[dir="rtl"] .progress-bar--inline,
[dir="rtl"] .ajax-progress-bar {
  float: right;
}

.progress-throbber,
.ajax-progress {
  display: inline-block;
}

[dir="rtl"] .progress-throbber,
[dir="rtl"] .ajax-progress {
  float: right;
}

.progress-throbber__widget,
.ajax-progress .throbber {
  background: url(../sass/forms/progress-throbber/progress-throbber.gif) no-repeat 0 -18px transparent;
  float: left;
  height: 15px;
  margin: 2px;
  width: 15px;
}

[dir="rtl"] .progress-throbber__widget,
[dir="rtl"] .ajax-progress .throbber,
.ajax-progress [dir="rtl"] .throbber {
  float: right;
}

.progress-throbber__widget-in-tr,
tr .ajax-progress .throbber {
  margin: 0 2px;
}

.progress-throbber__message,
.ajax-progress .message {
  padding-left: 20px;
}

.resizable-textarea,
.resizable-textarea textarea {
  width: 100%;
  vertical-align: bottom;
}

.resizable-textarea__grippie,
.resizable-textarea .grippie {
  background: url(../sass/forms/resizable-textarea/grippie.png) no-repeat center 2px #eeeeee;
  border: 1px solid #cccccc;
  border-top-width: 0;
  cursor: s-resize;
  height: 9px;
  overflow: hidden;
}

.table-drag__wrapper,
body.drag {
  cursor: move;
}

.table-drag__item,
tr.drag {
  background-color: #fffadb;
}

.table-drag__item-previous,
tr.drag-previous {
  background-color: #fff7c2;
}

.table-drag__handle,
.tabledrag-handle {
  cursor: move;
  float: left;
  height: 1.7em;
  margin-left: -1em;
  overflow: hidden;
  text-decoration: none;
  font-size: 12px;
}

[dir="rtl"] .table-drag__handle,
[dir="rtl"] .tabledrag-handle {
  float: right;
  margin-right: -1em;
  margin-left: 0;
}

.table-drag__handle:focus, .table-drag__handle:hover,
.tabledrag-handle:focus,
.tabledrag-handle:hover {
  text-decoration: none;
}

.table-drag__handle-icon,
.tabledrag-handle .handle {
  box-sizing: content-box;
  background: url(../sass/forms/table-drag/handle-icon.png) no-repeat 6px 9px;
  height: 13px;
  margin: -.4em .5em;
  padding: .42em .5em;
  width: 13px;
}

.table-drag__handle.is-hover .table-drag__handle-icon, .table-drag__handle-icon--is-hover, .is-hover.tabledrag-handle .table-drag__handle-icon,
.tabledrag-handle-hover .handle,
.table-drag__handle.is-hover .tabledrag-handle .handle,
.tabledrag-handle .table-drag__handle.is-hover .handle,
.is-hover.tabledrag-handle .handle {
  background-position: 6px -11px;
}

.table-drag__toggle-weight-wrapper,
.tabledrag-toggle-weight-wrapper {
  text-align: right;
}

[dir="rtl"] .table-drag__toggle-weight-wrapper,
[dir="rtl"] .tabledrag-toggle-weight-wrapper {
  text-align: left;
}

.table-drag__toggle-weight,
.tabledrag-toggle-weight {
  font-size: .9em;
}

.table-drag__indentation,
.indentation {
  float: left;
  height: 1.7em;
  margin: -.4em .2em -.4em -.4em;
  padding: .42em 0 .42em .6em;
  width: 20px;
}

[dir="rtl"] .table-drag__indentation,
[dir="rtl"] .indentation {
  float: right;
  margin: -.4em -.4em -.4em .2em;
  padding: .42em .6em .42em 0;
}

.table-drag__tree-child,
.table-drag__tree-child-last,
.tree-child-last,
.table-drag__tree-child-horizontal,
.tree-child-horizontal,
.tree-child {
  background: url(../sass/forms/table-drag/tree-child.png) no-repeat 11px center;
}

[dir="rtl"] .table-drag__tree-child,
[dir="rtl"] .table-drag__tree-child-last,
[dir="rtl"] .tree-child-last,
[dir="rtl"] .table-drag__tree-child-horizontal,
[dir="rtl"] .tree-child-horizontal,
[dir="rtl"] .tree-child {
  background-position: -65px center;
}

.table-drag__tree-child-last,
.tree-child-last {
  background-image: url(../sass/forms/table-drag/tree-child-last.png);
}

.table-drag__tree-child-horizontal,
.tree-child-horizontal {
  background-position: -11px center;
}
