/* Style The Dropdown Button */
.current-dd-page {
    /*background-color: none;*/
    color: #007ee5;
    padding: 5px;
    padding-left: 20px;
    font-size: 20px;
    border: none;
    cursor: pointer;
    background-image: url(../img/icon-arrow-down.png);
    background-position: 95% center;
    background-repeat: no-repeat;
    display: block;
    width: 150px;
    &:hover {
        text-decoration: none;
    }
}

/* The container <div> - needed to position the dropdown content */

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
    font-size: 20px;
    padding-left: 20px;
    display: none;
    position: absolute;
    background-color: #fff;
    width: 150px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
    &:hover {
        background-color: #b2af39;
    }
}

/* Links inside the dropdown */
.dropdown-content a {
    padding: 5px 5px 5px 0px;
    color: #007ee5;
    &:hover {
        text-decoration: none;
        color: #fff;
    }
    display: block;
}

/* Change color of dropdown links on hover */
/*.dropdown-content a:hover {background-color: #f1f1f1}*/

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {
    display: block;
}

/* Change the background color of the dropdown button when the dropdown content is shown */
/*.dropdown:hover{
    background-color: #3e8e41;
}*/