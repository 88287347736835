// Links
//
// Hyperlinks are used to allow the user to navigate to other resources or to
// download a resource.
//
// The order of link states are based on Eric Meyer's article:
// http://meyerweb.com/eric/thoughts/2007/06/11/who-ordered-the-link-states
//
// :visited - A link that has already been visited.
// :hover   - A link that is being hovered over.
// :focus   - A link that has been given keyboard focus.
// :active  - A link that is being actively clicked.
//
// Markup: links-a.twig
//
// Style guide: base.link

a,
%link {
  color: color(link);
  // Remove gaps in links underline in iOS 8+ and Safari 8+.
  -webkit-text-decoration-skip: objects;
  text-decoration: none;
  transition: all ease-in-out .1s;
}

.col-middle-top {
  a {
// breaking links across multiple lines in panels.
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;  
  }
}

// Any element can receive focus, but only links can be visited, so we can
// safely lower this selector's specificity. This also ensures visited links
// with additional styles won't have their styles overridden.
:visited {
  color: color(link-visited);
}

a:hover,
a:focus {
  // Add your styles.
  text-decoration: underline;
  transition: all ease-in-out .1s;
}

a:active {
  color: color(link-active);

  &:not(.button) {
    // Remove the gray background on active links in IE 10.
    background-color: transparent;
  }
}

// Remove the outline on focused links when they are also active or hovered
// in all browsers (opinionated).
a:active,
a:hover {
  outline-width: 0;
  transition: all ease-in-out .1s;
}

@media print {
  // Underline all links.
  :link,
  :visited {
    text-decoration: underline;
  }

  // Add visible URL after links.
  a[href]:after {
    content: ' (' attr(href) ')';
    font-weight: normal;
    font-size: $base-font-size;
    text-decoration: none;
  }

  // Only display useful links.
  a[href^='javascript:']:after,
  a[href^='#']:after {
    content: '';
  }
}

// Back to theme button: Only displays on national and local dataset
.parent-btn-wrapper {
  background-image: url('../img/line-theme.png');
  background-repeat: no-repeat;
  background-position: center bottom;
  padding-bottom: 55px;
  position: relative;
  z-index: 10;

  .back-to-parent {
    @include clearfix;
    background: #224272;
    border-radius: 50px;
    margin: auto;
    color:  white;
    text-decoration: none;
    display: table;
    color: white;
    text-align: center;
    border-bottom: 2px solid #101e2f;
    border-top: 1px solid #839099;
    -webkit-box-shadow: 0 10px 70px -10px rgba(0, 0, 0, .5);
    -moz-box-shadow: 0 10px 70px -10px rgba(0, 0, 0, .5);
    -o-box-shadow: 0 10px 70px -10px rgba(0, 0, 0, .5);
    box-shadow: 0 10px 70px -10px rgba(0, 0, 0, .5);

    .button-icon {
      display: block;
      float: left;
      padding: 8px 30px 0;

      img {
        width: 50px;
        display: block;
      }
    }

    .button-text {
      float: left;
      padding: 20px 100px 20px 30px;
      position: relative;

      &::after {
        position: absolute;
        top: 50%;
        margin-top: -10px;
        right: 25px;
        content: ' ';
        width: 20px;
        height: 20px;
        background-image: url('../img/icon-up-arrow.png');
        background-size: 20px;
        background-repeat: no-repeat;
        background-position: center;
      }
    }

    &.active {
      background: #3c588c;
    }

    &:hover,
    &:focus {
      background: #3c588c;
      transition: all ease-in-out .1s;
    }
  }
}

.field-item {
  margin-bottom: 10px;
}

.logged-in .views-field-edit-node {
  padding: 3px 8px; 
  background-color: #007ee5;
  float: right;
  border-radius: 5px;
  a {
    font-size: 14px;
    line-height: 16px;
    color: #fff;
  }
}

.logged-in .view-fsdf-dataset,
.logged-in .view-agencies {
  .views-field-title {
    display: inline-block;
  }
}

.logged-in th.views-field-edit-node {
  display: none;
}

.logged-in a.edit-link,
.logged-in a.add-link {
  display: inline-block;
  padding: 3px 8px;
  background-color: #007ee5;
  float: right;
  border-radius: 5px;
  font-size: 14px;
  line-height: 16px;
  color: #fff;
}

.logged-in .view-agencies {
  position: relative;
  a.add-link {
    display: block;
    position: absolute;
    top: -60px;
    right: 0;
  }
}

/* Dataset / use cases */
.logged-in .view-display-id-block_2 li.views-row {
  position: relative;
  .views-field-edit-node {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
}

 