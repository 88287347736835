// Progress throbber
//
// Shows the progress of a task using a throbber.
//
// Markup: progress-throbber.twig
//
// Style guide: forms.progress-throbber

.progress-throbber,
%progress-throbber {
  display: inline-block;

  @include rtl() {
    float: right;
  }

  &__widget {
    background: image-url('forms', 'progress-throbber/progress-throbber.gif') no-repeat 0 -18px transparent;
    float: left;
    height: 15px;
    margin: 2px;
    width: 15px;

    @include rtl() {
      float: right;
    }
  }

  &__widget-in-tr {
    margin: 0 2px;
  }

  &__message {
    padding-left: 20px;
  }
}

//
// Drupal selectors.
//

.ajax-progress {
  @extend %progress-throbber;

  .throbber {
    @extend %progress-throbber__widget;

    tr & {
      @extend %progress-throbber__widget-in-tr;
    }
  }

  .message {
    @extend %progress-throbber__message;
  }
}
