// Dependencies.
@import 'base/headings/headings';
@import 'components/clearfix/clearfix';

// Branding header
//
// Markup: header.twig
//
// Style guide: components.header

.header,
%header {
  position: relative;
  z-index: 5;
  background: url('../img/gradient-header.png');
  background-repeat: repeat-x;
  min-height: 243px;

  @include respond-to('xxl') {
    //background: linear-gradient(to top, rgba(33, 33, 33, 0), rgba(33, 33, 33, .2), rgba(33, 33, 33, .6));
  }

  .header-inner {
    border-bottom: 1px solid rgba(255, 255, 255, .3);
  }

  .layout-center {
    @extend %clearfix;
    position: relative;
  }

  // Wrapping link for logo.
  &__logo {
    float: left;
    margin: 0 10px 0 0;
    padding: 25px 0;

    @include rtl() {
      float: right;
      margin: 0 0 0 10px;
    }
  }

  // Logo image.
  &__logo-image {
    vertical-align: bottom;
  }

  // Wrapper for website name and slogan.
  &__name-and-slogan {
    @include visually-hidden;
    float: left;
  }

  // The name of the website.
  &__site-name {
    @extend %h1;
    margin: 0;
  }

  // The link around the name of the website.
  &__site-link {
    &:link,
    &:visited {
      color: color(text);
      text-decoration: none;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }

  // The slogan (or tagline) of a website.
  &__site-slogan {
    margin: 0;
  }

  // The secondary menu (login, etc.)
  &__secondary-menu {
    float: right;

    @include rtl() {
      float: left;
    }
  }

  // Wrapper for any blocks placed in the header region.
  &__region {
    // Clear the logo.
    //clear: both;
    float: right;
  }
}

// custom header styles
.header {
  width: 100%;

  .region-header .menu {
    padding: 45px 0 0;

    li a {
      color: white;
      font-size: 20px;
      text-decoration: none;
      padding: 40px 10px;
      cursor: pointer;

      &.active {
        border-bottom: 3px solid white;
      }

      &:hover,
      &:focus {
        background: rgba(255, 255, 255, .1);
        border-bottom: 3px solid rgba(255, 255, 255, .5);
      }
    }
  }

  // Login/Logout button.
  #block-system-user-menu {
    position: absolute;
    top: 115px;
    right: 12px;
    .menu {
      padding: 0;
      a {
        font-size: 16px;
        color: color(text);
        padding: 5px 10px;
        background: rgba(white, .9);
        border: 0;
        border-radius: 0 0 3px 3px;
      }
      a:hover,
      a:focus,
      a:active {
        background: darken(rgba(white, .9), 10%);
      }
    }
  }
}

// Header banner image behind the navigation and page heading
.region-banner {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  max-height: 100%;
  overflow: hidden;
  min-width: 1300px;
  opacity: .8;

  p {
    margin-top: 0;
  }

  img {
    position: relative;
    width: 1500px;
    height: 700px;
    margin-left: -750px;
    left: 50%;
    max-width: none;

    @include respond-to('xxxl') {
      width: 100%;
      height: auto;
      display: block;
      position: static;
      margin-left: 0;
    }
  }

  &:after {
    content: ' ';
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    background: url('../img/gradient-banner.png');
    background-repeat: repeat-x;
    min-height: 100%;
    background-size: 10px 591px;
    background-position: bottom;
  }
}

  .section-tranche-report .region-banner:after,
  .section-tranche .region-banner:after,
  .section-program-activity .region-banner:after {

      @include respond-to('xxxl') {
        background: radial-gradient(80% 50%, rgba(255, 255, 255, 0) 35%, rgba(245, 245, 245, .8) 70%, whitesmoke);
        height: 200%;
      }

  }
